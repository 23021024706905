import React from 'react';
import PropTypes from 'prop-types';

// Import icons to use as standalone <svg> tag
import {ReactComponent as Supportbase} from '../../assets/icons/supportbase.svg';
import {ReactComponent as Suitability} from '../../assets/icons/suitability.svg';
import {ReactComponent as Cost} from '../../assets/icons/cost.svg';
import {ReactComponent as AddedValue} from '../../assets/icons/added-value.svg';
import {ReactComponent as Risks} from '../../assets/icons/risks.svg';

// Import icons to use with <img> tag
import supportbase from '../../assets/icons/supportbase.svg';
import suitability from '../../assets/icons/suitability.svg';
import cost from '../../assets/icons/cost.svg';
import addedValue from '../../assets/icons/added-value.svg';
import risks from '../../assets/icons/risks.svg';

const Icon = ({className = '', icon = '', inline = true}) => {
    if (icon !== '') {
        if (inline) {
            const IconComponent = IconLibrary(icon).component;
            if (IconComponent != null) {
                return <span className={'svg-icon svg-icon-' + icon + ' ' + className}>
                    <IconComponent />
                </span>;
            } else {
                return null;
            }
        } else {
            const imgSrc = IconLibrary(icon).imgSrc;
            if (imgSrc != null) {
                return <img src={imgSrc} alt={icon} />
            } else {
                return null;
            }
        }
    } else {
        return null;
    }
};

const IconLibrary = (icon) => {
    switch (icon) {
        case 'supportbase':
            return {
                component: Supportbase,
                imgSrc: supportbase,
            };
        case 'suitability':
            return {
                component: Suitability,
                imgSrc: suitability,
            };
        case 'cost':
            return {
                component: Cost,
                imgSrc: cost,
            };
        case 'addedValue':
            return {
                component: AddedValue,
                imgSrc: addedValue,
            };
        case 'risks':
            return {
                component: Risks,
                imgSrc: risks,
            };
        default:
            // koekwaus
            return {
                component: null,
                imgSrc: null,
            };
    }
};

export default Icon;

Icon.propTypes = {
    className: PropTypes.string,
    icon: PropTypes.string.isRequired,
    inline: PropTypes.bool,
};
IconLibrary.propTypes = {
    icon: PropTypes.string.isRequired,
};