import {
    RESET_STATE,
    SET_SECTIONQUESTION,
    SET_TOPICQUESTION,
    SET_TOPICSTATUS,
    TOGGLE_ALLOWCONTACT,
    UPDATE_PROJECTINFO,
} from '../actions';

const projectData = (state = {}, {type, payload}) => {
    switch (type) {
        case SET_SECTIONQUESTION:
            return {
                ...state,
                sectionQuestion: {
                    ...state.sectionQuestion,
                    ...payload,
                },
            };
        case SET_TOPICQUESTION:
            return {
                ...state,
                topicQuestion: {
                    ...state.topicQuestion,
                    ...payload,
                },
            };
        case SET_TOPICSTATUS:
            return {
                ...state,
                topicStatus: {
                    ...state.topicStatus,
                    ...payload,
                },
            };
        case TOGGLE_ALLOWCONTACT:
            return {
                ...state,
                projectInfo: {
                    ...state.projectInfo,
                    allowContact: !state.projectInfo.allowContact,
                },
            };
        case UPDATE_PROJECTINFO:
            return {
                ...state,
                projectInfo: {
                    ...state.projectInfo,
                    ...payload,
                },
            };
        case RESET_STATE:
            return payload.projectData;
        default:
            return state;
    }
};

export default projectData;
