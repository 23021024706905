import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { generatePath, Link, useRouteMatch } from "react-router-dom";
import { Trans } from "react-i18next";

import { mapThemeButtonState } from "./mapping";
import Icon from "../../../components/global/Icon";

const defaultProps = {
  activeTheme: {},
  activeThemeUrlSlug: "",
  section: { themes: [] },
};
const ThemeNav = ({
  activeTheme = defaultProps.activeTheme,
  activeThemeUrlSlug = defaultProps.activeThemeUrlSlug,
  section = defaultProps.section,
}) => {
  if (section.themes.length === 0) {
    // No themes available, skip this component
    return null;
  }

  const overviewIsActive = activeTheme.hasOwnProperty("noItemFound");

  return (
    <div className="nav nav-overview">
      <OverviewButton isActive={overviewIsActive} />
      {section.themes.map((themeId) => (
        <ThemeButtonRedux
          activeThemeUrlSlug={activeThemeUrlSlug}
          id={themeId}
          key={themeId}
        />
      ))}
    </div>
  );
};

const OverviewButton = ({ isActive }) => {
  let { path } = useRouteMatch();

  return (
    <Link
      to={generatePath(path, { step: "2" })}
      className={"nav-item nav-link nav-brand" + (isActive ? " active" : "")}
    >
      <Trans>Toon volledig overzicht</Trans>
    </Link>
  );
};

const ThemeButton = ({ activeThemeUrlSlug, id, themes }) => {
  const theme = themes[id];
  const isActive = activeThemeUrlSlug === theme.urlSlug;
  let { path } = useRouteMatch();

  return (
    <Link
      to={generatePath(path, { step: 2, activeTheme: theme.urlSlug })}
      className={"nav-item nav-link" + (isActive ? " active" : "")}
    >
      <Icon className="fa-2x" icon={theme.icon} />

      <Trans i18nKey={`theme.theme${theme.id}.title`}>{theme.name}</Trans>
    </Link>
  );
};
const ThemeButtonRedux = connect(mapThemeButtonState)(ThemeButton);

ThemeNav.propTypes = {
  activeTheme: PropTypes.any.isRequired,
  activeThemeUrlSlug: PropTypes.string.isRequired,
  section: PropTypes.object.isRequired,
};
OverviewButton.propTypes = {
  isActive: PropTypes.bool.isRequired,
};
ThemeButton.propTypes = {
  activeThemeUrlSlug: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
};

export default ThemeNav;
