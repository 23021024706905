/*
 State
 */
export const mapStateForExport = (state, ownProps) => {
  return {
    mode:
      ownProps.location?.pathname === "/export/pdf/screen"
        ? "screen"
        : "button",
    state: state,
  };
};
