import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import {
  mapHeaderDispatch,
  mapHeaderState,
} from "../../containers/srl/_section2/mapping";
import Confirm, { ExportDialog, LoadProject } from "../global/Alert";
import { Button, ButtonGroup, Dropdown } from "react-bootstrap";
import cx from "classnames";
import JSZip from "jszip";
import { tryParseJSON } from "../../helpers/store";
import { Trans, useTranslation } from "react-i18next";

const Header = ({
  projectName,
  sectionId,
  section,
  exportToJSON,
  changesSaved = undefined,
  resetState,
  appDataSaved,
}) => {
  const [showLogo, setShowLogo] = useState(null);

  const HeaderSticky = () => {
    // Sticky Menu Area
    useEffect(() => {
      window.addEventListener("scroll", isSticky);
      return () => {
        window.removeEventListener("scroll", isSticky);
      };
    });

    /* Method that will fix header after a specific scrollable */
    const isSticky = (e) => {
      const header = document.querySelector(".header-section");
      const scrollTop = window.scrollY;
      scrollTop >= 250
        ? header.classList.add("is-sticky")
        : header.classList.remove("is-sticky");
    };
    return (
      <>
        <div
          className="row mb-4 header-section"
          style={{ backgroundColor: "white" }}
        >
          <div className="col">
            <h2 className="mb-0 h-100 d-flex align-items-center">
              <span className="font-weight-normal">
                <Trans i18nKey="appHeader.Part">Deel</Trans> {sectionId}:{" "}
                {t(section.name)}
              </span>
            </h2>
          </div>
          <div className="col-auto">
            {changesSaved !== undefined && (
              <span className={changesClasses}>
                <Trans i18nKey="themeHeader.changes">
                  Let op! download om je wijzigingen op te slaan
                </Trans>
              </span>
            )}

            <Dropdown as={ButtonGroup} alignRight>
              <Button
                className="ml-3"
                onClick={() => doExport()}
                variant="warning"
              >
                <Trans i18nKey="Save">Download</Trans>
              </Button>

              <Dropdown.Toggle
                split
                variant="warning"
                id="dropdown-split-basic"
              />

              <Dropdown.Menu>
                <Dropdown.Item onClick={() => setOpenDialog(true)}>
                  <Trans i18nKey="Open">Openen</Trans>...
                </Dropdown.Item>
                <Dropdown.Item>
                  <div
                    onClick={() => {
                      setShowLogo(true);
                      setExportFormat("pdf");
                      setShowExportDialog(true);
                    }}
                  >
                    <Trans i18nKey="SaveAsPDF">Opslaan als PDF</Trans>...
                  </div>
                </Dropdown.Item>
                <Dropdown.Item>
                  <div
                    onClick={() => {
                      setShowLogo(false);
                      setExportFormat("pdf");
                      setShowExportDialog(true);
                    }}
                  >
                    <Trans i18nKey="SaveAsPDFWithoutLogo">
                      Opslaan als PDF zonder RWS logo
                    </Trans>
                    ...
                  </div>
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => {
                    setExportFormat("excel");
                    setShowExportDialog(true);
                  }}
                >
                  <Trans i18nKey="SaveAsExcel">Opslaan als Excel</Trans>...
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Button
              className="ml-3"
              variant="warning"
              onClick={() => setShowDeleteAlert(true)}
            >
              <Trans i18nKey="Newproject">Nieuw project</Trans>...
            </Button>
          </div>
        </div>
      </>
    );
  };

  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [showOpenDialog, setOpenDialog] = useState(false);
  const [showExportDialog, setShowExportDialog] = useState(false);
  const [exportFormat, setExportFormat] = useState("pdf");
  const history = useHistory();
  const { t } = useTranslation();

  const title = projectName ? projectName : `[${t("Naam van dit project")}]`;
  const changesClasses = cx("font-italic", {
    "text-muted": true,
  });
  const loadNewState = (fileContent) => {
    resetState(fileContent);
    setOpenDialog(false);

    // Redirect to first page
    history.push("/srl/1");
  };
  const doExport = () => {
    exportToJSON().then(() => {
      appDataSaved();
    });
  };

  return (
    <>
      {showDeleteAlert && (
        <Confirm
          title="emptyProject"
          onCancel={() => {
            setShowDeleteAlert(false);
          }}
          onConfirm={() => {
            resetState();
            setShowDeleteAlert(false);

            // Redirect to first page
            history.push("/srl/1");
          }}
        />
      )}

      {showOpenDialog && (
        <OpenFile
          loadNewState={loadNewState}
          onCancel={() => {
            setOpenDialog(false);
          }}
        />
      )}

      {showExportDialog && (
        <ExportProjectDialog
          format={exportFormat}
          showLogo={showLogo}
          onCancel={() => {
            setShowExportDialog(false);
          }}
        />
      )}

      <HeaderSticky />
      <h1 className="mb-5">{title}</h1>
    </>
  );
};

const OpenFile = (props) => {
  const [fileIsValid, setFileIsValid] = useState(false);
  const [fileContent, setFileContent] = useState(false);

  const onConfirm = () => {
    if (fileIsValid && fileContent) {
      props.loadNewState(fileContent);
    }
  };

  const handleFile = (f) => {
    JSZip.loadAsync(f).then(
      function (zip) {
        let jsonFound = false;

        zip.forEach(function (relativePath, zipEntry) {
          // 2) print entries
          if (jsonFound === false && zipEntry.name.endsWith(".json")) {
            jsonFound = true;

            zipEntry.async("string").then(function (data) {
              const newData = tryParseJSON(data);

              if (newData) {
                setFileIsValid(true);
                setFileContent(newData);
              }
            });
          }
        });
      },
      function (e) {
        //error
      }
    );
  };

  return (
    <LoadProject
      fileIsValid={fileIsValid}
      onCancel={props.onCancel}
      onConfirm={onConfirm}
      handleFile={handleFile}
    />
  );
};

export const ExportProjectDialog = (props) => {
  return (
    <ExportDialog
      showLogo={props.showLogo}
      format={props.format}
      onCancel={props.onCancel}
      onConfirm={props.onCancel}
    />
  );
};

Header.propTypes = {
  projectName: PropTypes.string,
  sectionId: PropTypes.number.isRequired,
  section: PropTypes.object.isRequired,
};

export default connect(mapHeaderState, mapHeaderDispatch)(Header);
