import React, { useState } from "react";
import { connect } from "react-redux";
import { mapOverviewDispatch, mapOverviewState } from "./mapping";
import { ChartLegendRedux } from "./Theme";
import ScrollToTop from "../../../components/global/ScrollToTop";
import Icon from "../../../components/global/Icon";
import OverviewChart from "./OverviewChart";
import {
  generatePath,
  useRouteMatch,
  useHistory,
  Link,
} from "react-router-dom";
import { Button, ButtonGroup, Dropdown } from "react-bootstrap";
import { Trans } from "react-i18next";
import { exportToJSON } from "../../../helpers/store";
import { ExportProjectDialog } from "../../../components/srl/Header";

const StartButton = ({ themes }) => {
  let { path } = useRouteMatch();
  const history = useHistory();
  return (
    <Button
      className="btn btn-primary d-flex align-items-center justify-content-space-between"
      variant="warning"
      onClick={() =>
        history.push(
          generatePath(path, { step: 2, activeTheme: themes[1].urlSlug })
        )
      }
    >
      <Trans i18nKey={"start"}>Start met invullen</Trans>
      <i className="fas fa-chevron-right cursor-pointer ml-2" />
    </Button>
  );
};

const DownloadButton = ({
  topicQuestionData,
  topicStatusData,
  appDataSaved,
}) => {
  const [showLogo, setShowLogo] = useState(null);
  const [showExportDialog, setShowExportDialog] = useState(false);
  const [exportFormat, setExportFormat] = useState("pdf");

  return Object.keys(topicQuestionData).length > 0 ||
    Object.keys(topicStatusData).length > 0 ? (
    <>
      {showExportDialog && (
        <ExportProjectDialog
          format={exportFormat}
          showLogo={showLogo}
          onCancel={() => {
            setShowExportDialog(false);
          }}
        />
      )}

      <Dropdown className="mt-3" as={ButtonGroup} alignRight>
        <Button
          className="pr-3"
          onClick={() =>
            exportToJSON().then(() => {
              appDataSaved();
            })
          }
          variant="warning"
        >
          <Trans i18nKey="Save">Download</Trans>
        </Button>

        <Dropdown.Toggle split variant="warning" id="dropdown-split-basic" />

        <Dropdown.Menu>
          <Dropdown.Item>
            <div
              onClick={() => {
                setShowLogo(true);
                setExportFormat("pdf");
                setShowExportDialog(true);
              }}
            >
              <Trans i18nKey="SaveAsPDF">Opslaan als PDF</Trans>...
            </div>
          </Dropdown.Item>
          <Dropdown.Item>
            <div
              onClick={() => {
                setShowLogo(false);
                setExportFormat("pdf");
                setShowExportDialog(true);
              }}
            >
              <Trans i18nKey="SaveAsPDFWithoutLogo">
                Opslaan als PDF zonder RWS logo
              </Trans>
              ...
            </div>
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              setExportFormat("excel");
              setShowExportDialog(true);
            }}
          >
            <Trans i18nKey="SaveAsExcel">Opslaan als Excel</Trans>...
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  ) : (
    <></>
  );
};

const StartButtonRedux = connect(mapOverviewState)(StartButton);
const DownloadButtonRedux = connect(
  mapOverviewState,
  mapOverviewDispatch
)(DownloadButton);

const Overview = ({ themes = {} }) => {
  return (
    <div className="row">
      <ScrollToTop y={210} />
      <div className="col-9">
        <div className="overview-chart-wrapper">
          <OverviewChart />
          <ThemeIcons themes={Object.values(themes)} />
        </div>
      </div>
      <div className="col-3">
        <ChartLegendRedux split={false} />
        <ButtonGroup vertical style={{ position: "absolute", bottom: 124 }}>
          <StartButtonRedux />
          <DownloadButtonRedux />
        </ButtonGroup>
      </div>
    </div>
  );
};

const ThemeIcons = (props) => {
  let { path } = useRouteMatch();
  const { themes } = props;

  return themes.map((theme) => {
    return (
      <Link
        key={`theme-overview-icon-${theme.id}`}
        to={generatePath(path, { step: 2, activeTheme: theme.urlSlug })}
      >
        <div
          id={`theme-overview-icon-${theme.id}`}
          className="theme-overview-icon"
        >
          <Icon className="icon-gray fa-4x" icon={theme.icon} />
          <div className="h4 text-body">
            <Trans i18nKey={`theme.theme${theme.id}.title`}>{theme.name}</Trans>
          </div>
        </div>
      </Link>
    );
  });
};

export default connect(mapOverviewState)(Overview);
