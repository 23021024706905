import React from "react";
import {connect} from 'react-redux';
import Parser from "html-react-parser";

import {mapSection3State} from "./mapping";
import Header from "../../../components/srl/Header";
import SectionQuestion from "../_sectionQuestion/SectionQuestion";
import ScrollToTop from "../../../components/global/ScrollToTop";
import Language from "../../../helpers/language";
import {Button} from "react-bootstrap";
import {Trans, useTranslation} from "react-i18next";

const Section3 = ({sectionQuestions}) => {
    const handleSubmit = e => e.preventDefault();
    const {t} = useTranslation();

    let counter = 1;
    return <>
        <ScrollToTop />
        <Header sectionId={3} />
        <div className="row">
            <div className="col-md-8">
                <form onSubmit={handleSubmit}>
                    {sectionQuestions.length > 0 && <fieldset className="mb-4">
                        {sectionQuestions.map(id => <SectionQuestion index={counter++} questionId={id} key={id} />)}
                    </fieldset>}
                </form>
                <div className="alert alert-info">
                    {Parser(t('section3.reflectieEndText'))}
                </div>
                <Button variant="warning">
                    <Trans i18nKey="Save">
                    {Language.getTranslation('save', 'buttons')}
                    </Trans>
                    </Button>
            </div>
        </div>
    </>
};

export default connect(mapSection3State)(Section3);
