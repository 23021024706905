import React from "react";
import { Link } from "react-router-dom";
import ScrollToTop from "../global/ScrollToTop";
import { Trans } from "react-i18next";

const Help = () => {
  return (
    <main>
      <ScrollToTop />
      <div className="container py-4">
        <div className="row">
          <div className="col">
            <h1>
              <Trans i18nKey="appHelp.Title">Hoe werkt de SRL-tool?</Trans>
            </h1>

            <h2>
              <Trans i18nKey="appHelp.Paragraph1.Subtitle">Wat is SRL</Trans>
            </h2>
            <p>
              <Trans i18nKey="appHelp.Paragraph1.Text1">
                De SRL is een vragenlijst en omvat verschillende aspecten. Om de
                SRL te bepalen wordt gekeken naar meerdere componenten:
              </Trans>
            </p>

            <ol>
              <li>
                <Trans i18nKey="appHelp.Paragraph1.Listitem1">
                  Waarde: de meerwaarde van de innovatie voor de
                  eindgebruiker(s).
                </Trans>
              </li>
              <li>
                <Trans i18nKey="appHelp.Paragraph1.Listitem2">
                  Draagvlak: het enthousiasme binnen de organisatie, maar ook de
                  hindermacht.
                </Trans>
              </li>
              <li>
                <Trans i18nKey="appHelp.Paragraph1.Listitem3">
                  Kosten: zowel voor de ontwikkeling als de toepassing.
                </Trans>
              </li>
              <li>
                <Trans i18nKey="appHelp.Paragraph1.Listitem4">
                  Inpasbaarheid: inpasbaarheid in bestaande processen en de
                  veranderdruk die een innovatie met zich meebrengt.
                </Trans>
              </li>
              <li>
                <Trans i18nKey="appHelp.Paragraph1.Listitem5">
                  Risico’s: een snelle check of de risico’s op de verschillende
                  fronten bekend en beheersbaar zijn.
                </Trans>
              </li>
            </ol>

            <h2>
              <Trans i18nKey="appHelp.Paragraph2.Subtitle">
                Online SRL-tool
              </Trans>
            </h2>
            <p>
              <Trans i18nKey="appHelp.Paragraph2.Text1">
                Alle 5 de componenten komen terug in de online SRL-tool. Per
                component is een set vragen opgesteld die u in de tool kunt
                invullen. Zo hangen onder de component ‘inpasbaarheid’ onder
                meer vragen over de inpasbaarheid in de huidige wet- en
                regelgeving, inpasbaarheid in contracten, inpasbaarheid in
                werkprocessen.
              </Trans>
            </p>

            <p>
              <Trans i18nKey="appHelp.Paragraph2.Text2">
                De online SRL-tool bestaat uit 3 stappen, onderaan ieder pagina
                kunt u verder naar het volgende onderdeel. Dat doet u door te
                klikken op de gele knop ‘volgende stap’:
              </Trans>
            </p>

            <ol>
              <li>
                <Trans i18nKey="appHelp.Paragraph2.Listitem1">
                  <strong>Projectinformatie:</strong> hier kunt u toevoegen: de
                  naam van het project, uw naam, rol, startdatum, informatie
                  over het project
                </Trans>
              </li>
              <li>
                <Trans i18nKey="appHelp.Paragraph2.Listitem2.Text1">
                  <strong>SRL-vragen:</strong> de componenten staan bovenaan de
                  pagina. Als u hierop klikt gaat u naar de verschillende
                  onderdelen van de betreffende component. Klik op het grijze {">"},
                  dan klapt het menu uit.
                </Trans>
                <br />
                <Trans i18nKey="appHelp.Paragraph2.Listitem2.Text2">
                  De beantwoording gebeurt in 2 stappen:
                </Trans>
                <br />
                <ol>
                  <li>
                    <Trans i18nKey="appHelp.Paragraph2.Listitem2.Orderedlist1">
                      U typt uw antwoorden op een aantal checkvragen. Ook kunt u
                      actiepunten noemen.
                    </Trans>
                  </li>
                  <li>
                    <Trans i18nKey="appHelp.Paragraph2.Listitem2.Orderedlist2">
                      Per vraag kent u een kleur toe aan de status van de vraag:
                    </Trans>
                    <StatusColor color="#fff" text="Nog niet gestart" />
                    <StatusColor color="#cccccc" text="Niet van toepassing" />
                    <StatusColor color="#696969" text="Nog onbekend" />
                    <StatusColor
                      color="#ffb612"
                      text="Lijkt niet in orde, mogelijk aandachtspunt"
                    />
                    <StatusColor
                      color="#d52b1e"
                      text="Niet in orde, aandachtspunt"
                    />
                    <StatusColor
                      color="#39870c"
                      text="Lijkt in orde, maar weet het nog niet zeker"
                    />
                    <StatusColor
                      color="#275937"
                      text="In orde (gevalideerd/geregeld)"
                    />
                  </li>
                </ol>
                <Trans i18nKey="appHelp.Paragraph2.Listitem2.Outro1">
                  Bent u nog bezig? Dan kiest u voor grijs, licht groen of licht
                  rood. Weet u het zeker? Dan kiest u voor donker rood of donker
                  groen.
                </Trans>
                <br />
                <Trans i18nKey="appHelp.Paragraph2.Listitem2.Outro2">
                  Daarna klikt u op de blauwe knop ‘Terug’ om naar de andere
                  vragen van de component te gaan. Nadat u vragen hebt
                  beantwoord en kleuren hebt toegekend is per component te zien
                  wat de stand van zaken is. Zo is meteen duidelijk waar nog
                  (extra) aandacht nodig is.
                </Trans>
              </li>
              <li>
                <Trans i18nKey="appHelp.Paragraph2.Listitem3">
                  <strong>Reflectie:</strong> hier kunt u aangeven wat de
                  uitkomst van de tool bij u oproept. Wat is op orde, waar moet
                  u nog aan werken?
                </Trans>
              </li>
            </ol>

            <h2>Dashboard</h2>

            <p>
              <Trans i18nKey="appHelp.Paragraph3.Text1">
                De resultaten van alle componenten komen samen in een dashboard.
                Aan de gekleurde taartpunten ziet u hoe de innovatie scoort op
                de verschillende componenten. De kleuren geven een signaal wat
                er nog moet gebeuren. Hoe meer rood, hoe meer er nog moet
                gebeuren. Hoe meer groen, des te meer bevindt de innovatie zich
                in het eindstadium en is de organisatie klaar om de innovatie
                toe te passen.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="appHelp.Paragraph3.Text2">
                Als u met uw muis over de taartpunten heen gaat, verschijnt de
                toelichting die u zelf hebt aangegeven bij de beantwoording.
              </Trans>
            </p>

            <h2>
              <Trans i18nKey="appHelp.Paragraph4.Subtitle">
                Bewaren van de resultaten
              </Trans>
            </h2>

            <p>
              <Trans i18nKey="appHelp.Paragraph4.Text1">
                U kunt meteen met de tool aan de slag. U hoeft geen account aan
                te maken of in te loggen. Zodra u start wordt een set
                basisgegevens opgeslagen in een database, die gekoppeld is aan
                de website. De rest van de data wordt niet opgeslagen in de
                database, maar in een apart (.srl-) bestand. U kunt dit bestand
                bewaren op uw computer om het later weer in de tool te openen en
                te bewerken. Het werkt wat dat betreft net zoals de klassieke
                Office-toepassingen.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="appHelp.Paragraph4.Text2">
                Let op: voor het afsluiten van de tool moet u zelf aangeven dat
                u het project wilt opslaan. Klik hiervoor op de gele knop
                ‘Opslaan’, boven aan de pagina.
              </Trans>
            </p>
            <p>
              <Trans i18nKey="appHelp.Paragraph4.Text3">
                In iedere stap kunt u een pdf downloaden met de antwoorden en
                uitkomsten, klik hiervoor op de gele knop ‘PDF downloaden’. Ook
                kunt u op ieder moment opslaan, klik dan op de gele knop
                ‘Opslaan’.
              </Trans>
            </p>
            <h2>
              <Trans i18nKey="appHelp.Paragraph5.Subtitle">Feedback</Trans>
            </h2>
            <p>
              <Trans i18nKey="appHelp.Paragraph5.Text1">
                We zijn benieuwd wat u van de tool vindt. Laat uw gegevens
                achter via de knop <Link to="/feedback">Feedback</Link> en een
                medewerker van Rijkswaterstaat neemt contact met u op.
              </Trans>
            </p>
          </div>
        </div>
      </div>
    </main>
  );
};

const StatusColor = (props) => {
  const { color, text } = props;
  const border = color === "#fff" ? "1px solid #bbb" : "none";

  return (
    <div className="d-flex align-items-center legend-item">
      <span
        className="legend-bullet"
        style={{
          border: border,
          backgroundColor: color,
          width: 15,
          height: 15,
        }}
      />
      <span>
        <Trans>{text}</Trans>
      </span>
    </div>
  );
};

export default Help;
