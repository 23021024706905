import React from "react";
import PropTypes from "prop-types";
import { Editor } from "@tinymce/tinymce-react";

import config from "../../config/config.json";
import { defaultTinyMceConfig, smallTinyMceConfig } from "../../config/tinymce";

const Wysiwyg = ({ handleChange, useSmallWysiwyg = false, value = "" }) => {
  const defaultOptions = useSmallWysiwyg
    ? smallTinyMceConfig
    : defaultTinyMceConfig;
  const editorOptions = {
    ...defaultOptions,
    height: 200,
    language: "en",
    menubar: false,
    statusbar: false,
  };

  return (
    <Editor
      apiKey={config.tinymceKey}
      init={editorOptions}
      value={value}
      onEditorChange={handleChange}
    />
  );
};

Wysiwyg.propTypes = {
  handleChange: PropTypes.func.isRequired,
  useSmallWysiwyg: PropTypes.bool,
  value: PropTypes.string,
};

export default Wysiwyg;
