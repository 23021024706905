import { setSectionQuestion } from "../actions";

/*
 State
 */
export const mapSectionQuestionState = (state, { questionId }) => {
  return {
    answer: state.projectData.sectionQuestion[questionId] ?? "",
    question: state.structure.sectionQuestion[questionId],
  };
};

/*
 Dispatch actions
 */
export const mapSectionQuestionDispatch = (dispatch) => {
  return {
    setSectionQuestion: (sectionQuestion) =>
      dispatch(setSectionQuestion(sectionQuestion)),
  };
};
