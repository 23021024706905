import React from "react";
import PropTypes from "prop-types";
import Parser from "html-react-parser";

import Wysiwyg from "../../containers/global/Wysiwyg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import RangeSlider from "react-bootstrap-range-slider";

const FormField = (props) => {
  const {
    activeValue,
    color,
    handleChange,
    helpText,
    label,
    name,
    radioType,
    type,
    options,
    useSmallWysiwyg,
    value,
  } = props;
  const allowedInputTypes = ["date", "email", "tel", "text"];
  if (type === undefined) {
    return null;
  }
  const handleFieldChange = (e) => {
    if (type === "wysiwyg") {
      handleChange(name, e);
    } else {
      handleChange(name, e.target.value);
    }
  };

  const components = {
    checkbox: Checkbox,
    radio: Radio,
    text: InputField,
    textarea: TextArea,
    textareaSmall: TextAreaSmall,
    wysiwyg: Wysiwyg,
    select: Select,
    range: Range,
  };
  let Component;
  if (allowedInputTypes.includes(type)) {
    Component = components["text"];
  } else {
    if (!components.hasOwnProperty(type)) {
      return null;
    }
    Component = components[type];
  }

  switch (type) {
    case "checkbox":
      return (
        <div className="form-group">
          <div className="custom-control custom-checkbox">
            <Component
              {...props}
              handleChange={handleFieldChange}
              value={value}
            />
            {label && (
              <label className="custom-control-label" htmlFor={name}>
                {Parser(label)}
              </label>
            )}
            {helpText && (
              <div className={"form-text text-muted"}>{Parser(helpText)}</div>
            )}
          </div>
        </div>
      );
    case "select":
      return (
        <div className="form-group">
          {label && <label htmlFor={name}>{Parser(label)}</label>}
          {helpText && (
            <div className={"form-text text-muted"}>{Parser(helpText)}</div>
          )}
          <Component {...props} handleChange={handleFieldChange} />
        </div>
      );
    case "radio":
      if (radioType === "colorSwitch") {
        return (
          <div className="col">
            <div className="form-group">
              <OverlayTrigger
                placement={"bottom"}
                overlay={
                  <Tooltip id={`tooltip-${color}`}>{Parser(label)}</Tooltip>
                }
              >
                <div
                  className={"custom-control custom-radio colorSwitch-" + color}
                >
                  <Component
                    {...props}
                    checked={value === activeValue}
                    handleChange={handleFieldChange}
                    value={value}
                  />
                  {label && (
                    <label
                      className="custom-control-label"
                      htmlFor={name + "-" + value}
                    >
                      <span className="sr-only">{Parser(label)}</span>
                    </label>
                  )}
                  {helpText && (
                    <div className={"form-text text-muted"}>
                      {Parser(helpText)}
                    </div>
                  )}
                </div>
              </OverlayTrigger>
            </div>
          </div>
        );
      } else {
        return (
          <div className="form-group">
            <div className="custom-control custom-radio">
              <Component
                {...props}
                checked={value === activeValue}
                handleChange={handleFieldChange}
                value={value}
              />
              {label && (
                <label
                  className="custom-control-label"
                  htmlFor={name + "-" + value}
                >
                  {label}
                </label>
              )}
              {helpText && (
                <div className={"form-text text-muted"}>{Parser(helpText)}</div>
              )}
            </div>
          </div>
        );
      }
    case "range":
      return (
        <div className="form-group">
          {label && <label htmlFor={name}>{Parser(label)}</label>}
          {helpText && (
            <div className={"form-text text-muted"}>{Parser(helpText)}</div>
          )}
          <Component {...props} handleChange={handleFieldChange} />

          <div className="d-flex justify-content-between text-muted">
            {options.minLabel && <div>{options.minLabel}</div>}
            {options.maxLabel && <div>{options.maxLabel}</div>}
          </div>
        </div>
      );
    case "wysiwyg":
      return (
        <div className="form-group">
          {label && <label htmlFor={name}>{Parser(label)}</label>}
          {helpText && (
            <div className={"form-text text-muted"}>{Parser(helpText)}</div>
          )}
          <Component
            {...props}
            useSmallWysiwyg={useSmallWysiwyg}
            value={value}
          />
        </div>
      );
    default:
      return (
        <div className="form-group">
          {label && <label htmlFor={name}>{Parser(label)}</label>}
          {helpText && (
            <div className={"form-text text-muted"}>{Parser(helpText)}</div>
          )}
          <Component
            {...props}
            handleChange={handleFieldChange}
            value={value}
          />
        </div>
      );
  }
};

const Editor = ({ handleChange, value, useSmallWysiwyg }) => {
  return (
    <Wysiwyg
      handleChange={handleChange}
      useSmallWysiwyg={useSmallWysiwyg}
      value={value}
    />
  );
};
const InputField = ({ handleChange, name, type, value, disabled = false }) => {
  return (
    <input
      className="form-control"
      id={name}
      name={name}
      onChange={handleChange}
      type={type}
      value={value}
      disabled={disabled}
    />
  );
};
const Checkbox = ({ handleChange, name, value, disabled = false }) => {
  return (
    <input
      className="custom-control-input"
      id={name}
      name={name}
      onChange={handleChange}
      type="checkbox"
      value={value}
      checked={value === true}
      disabled={disabled}
    />
  );
};
const Radio = ({ handleChange, name, value, checked }) => {
  return (
    <input
      checked={checked}
      className="custom-control-input"
      id={name + "-" + value}
      name={name}
      onChange={handleChange}
      type="radio"
      value={value}
    />
  );
};
const TextArea = ({
  handleChange,
  name,
  placeholder = "",
  rows = 3,
  value,
}) => {
  return (
    <textarea
      className="form-control"
      id={name}
      name={name}
      onChange={handleChange}
      rows={rows}
      value={value}
      placeholder={placeholder}
    />
  );
};
const TextAreaSmall = ({
  handleChange,
  name,
  placeholder = "",
  rows = 3,
  value,
}) => {
  return (
    <textarea
      className="form-control form-control-sm"
      id={name}
      name={name}
      onChange={handleChange}
      rows={rows}
      value={value}
      placeholder={placeholder}
    />
  );
};
const Select = ({ handleChange, name, options, value }) => {
  return (
    <select
      className="form-control"
      id={name}
      name={name}
      onChange={handleChange}
      value={value}
    >
      <option value="">---</option>
      {options.map((option, index) => {
        return (
          <option value={option} key={`option-${name}-${index}`}>
            {option}
          </option>
        );
      })}
    </select>
  );
};
const Range = ({ handleChange, name, options, value }) => {
  return (
    <RangeSlider
      value={value}
      onChange={handleChange}
      min={options?.minValue || 1}
      max={options?.minValue || 5}
      size="sm"
      tooltip="off"
    />
  );
  // return <input type="range" onChange={handleChange} className="custom-range" value={value} min={options?.minValue || 1} max={options?.minValue || 5} id={name} />
};

const defaultPropTypes = {
  handleChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
};
FormField.propTypes = {
  ...defaultPropTypes,
  label: PropTypes.string,
  radioType: PropTypes.string,
  type: PropTypes.string.isRequired,
};
Editor.propTypes = {
  handleChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  useSmallWysiwyg: PropTypes.bool,
};
InputField.propTypes = {
  ...defaultPropTypes,
};
Checkbox.propTypes = {
  ...defaultPropTypes,
};
Radio.propTypes = {
  ...defaultPropTypes,
  checked: PropTypes.bool.isRequired,
};
TextArea.propTypes = {
  ...defaultPropTypes,
  rows: PropTypes.number,
};

export default FormField;
