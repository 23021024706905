import React from "react";
import { Route, Switch, useParams, useRouteMatch } from "react-router-dom";
import { connect } from "react-redux";
import { Trans } from "react-i18next";

import {
  mapChartLegendState,
  mapThemeSwitchState,
  mapTopicNavHeaderState,
  mapTopicNavigationState,
  mapTopicNavState,
  mapTopicSectionState,
  mapTopicState,
} from "./mapping";
import {
  TopicNav,
  TopicNavHeader,
  TopicNavigation,
} from "../../../components/srl/_section2/TopicNav";
import Topic from "../../../components/srl/_section2/Topic";
import ThemeChart from "../../../components/srl/_section2/ThemeChart";
import helpers from "../../../helpers";
import PropTypes from "prop-types";
import Colors from "../../../css/export-theme-for-react.scss";

const Theme = () => {
  let { path } = useRouteMatch();

  return (
    <div className="row">
      <div className="col-5">
        <Switch>
          <Route
            path={`${path}/:activeTopic?`}
            render={(routeProps) => <ThemeSwitchRedux {...routeProps} />}
          />
        </Switch>
      </div>
      <div className="col">
        <Switch>
          <Route
            path={`${path}`}
            render={(routeProps) => (
              <ThemeChart activeTheme={routeProps.match.params.activeTheme} />
            )}
          />
        </Switch>

        <div className="mt-4">
          <ChartLegendRedux />
        </div>
      </div>
    </div>
  );
};

const ThemeSwitch = ({ themes, topics }) => {
  const TopicSectionRedux = connect(mapTopicSectionState)(TopicSection);
  const { activeTheme, activeTopic } = useParams();

  const theme = helpers.getItemFromObject(themes, "urlSlug", activeTheme);
  let topic;
  if (activeTopic === undefined) {
    topic = { id: 0 };
  } else {
    topic = helpers.getItemFromObject(topics, "urlSlug", activeTopic);
  }

  return (
    <TopicSectionRedux activeThemeId={theme.id} activeTopicId={topic.id} />
  );
};
const ThemeSwitchRedux = connect(mapThemeSwitchState)(ThemeSwitch);

const TopicSection = ({ activeThemeId, activeTopicId }) => {
  const TopicNavHeaderRedux = connect(mapTopicNavHeaderState)(TopicNavHeader);
  const TopicNavRedux = connect(mapTopicNavState)(TopicNav);
  const TopicRedux = connect(mapTopicState)(Topic);
  const TopicNavigationRedux = connect(mapTopicNavigationState)(
    TopicNavigation
  );

  return (
    <div className="card bg-light border-0">
      <div className="card-body">
        <TopicNavHeaderRedux activeThemeId={activeThemeId} />
        {activeTopicId === 0 ? (
          <TopicNavRedux activeThemeId={activeThemeId} />
        ) : (
          <TopicRedux
            activeThemeId={activeThemeId}
            activeTopicId={activeTopicId}
          />
        )}

        <TopicNavigationRedux
          activeThemeId={activeThemeId}
          activeTopicId={activeTopicId}
        />
      </div>
    </div>
  );
};

const ChartLegend = ({ topicStatusData = [], split = true }) => {
  if (split === false) {
    return <LegendList list={topicStatusData} />;
  }

  return (
    <div className="row">
      <div className="col">
        <LegendList list={topicStatusData.splice(0, 3)} />
      </div>
      <div className="col">
        <LegendList list={topicStatusData} />
      </div>
    </div>
  );
};
const ChartLegendRedux = connect(mapChartLegendState)(ChartLegend);

const LegendList = ({ list = [] }) => {
  return list.map((item) => {
    const statusColorHex = Colors[item.color];
    const border = item.id === 1 ? "1px solid #bbb" : "none";

    return (
      <div
        className="d-flex align-items-center mb-2 smaller legend-item"
        key={`legend-item-${item.id}`}
      >
        <span
          className="legend-bullet"
          style={{ border: border, backgroundColor: statusColorHex }}
        />
        <span className="font-weight-bold size-small">
          <Trans>{item.name}</Trans>
        </span>
      </div>
    );
  });
};

export { Theme as default, ChartLegendRedux };

ChartLegend.propTypes = {
  topicStatusData: PropTypes.array.isRequired,
};

LegendList.propTypes = {
  list: PropTypes.array.isRequired,
};
