import React from "react";
import { connect } from "react-redux";
import * as FileSaver from "file-saver";
import * as ExcelJS from "exceljs";
import { htmlToText } from "html-to-text";
import { Trans, useTranslation } from "react-i18next";

import { mapStateForExport } from "./mapping";
import { Button } from "react-bootstrap";
import { ExportHelper } from "./ExportHelper";
import { StatusHelper } from "../../helpers/statusHelper";

const Excel = ({ state }) => {
  const { i18n } = useTranslation();
  const exportToCSV = () => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileName = ExportHelper.createFilename(
      state.projectData.projectInfo.projectName,
      "xlsx"
    );

    createExportDataBlob(state, i18n).then((blob) => {
      const data = new Blob([blob], { type: fileType });
      FileSaver.saveAs(data, fileName);
    });
  };

  return (
    <Button size="lg" variant="warning" onClick={exportToCSV}>
      <Trans i18nKey="exportDialog.downloadFileBtn">Bestand downloaden</Trans>
    </Button>
  );
};

const createExportDataBlob = (state, i18n) => {
  const { projectData, structure } = state;
  const projectInfo = projectData.projectInfo;
  const sectionQuestion = projectData.sectionQuestion;

  // New empty workbook
  const wb = new ExcelJS.Workbook();
  let ws;

  // Projectinformatie
  ws = wb.addWorksheet(
    i18n.t("section.projectinformatie"),
    structure.section[1].name
  );

  ws.addRows([
    [i18n.t("exportDialog.NameOfInnovation"), projectInfo.projectName],
    [i18n.t("exportDialog.NameOfFillers"), projectInfo.author],
    [i18n.t("exportDialog.DateOfToday"), projectInfo.currentDate],
    [""],
    [
      toText(
        i18n
          .t(
            "sectionQuestion.sectionQuestion2.title",
            structure.sectionQuestion[2]["name"]
          )
          .split("(")[0]
      ),
      toText(sectionQuestion[2]),
    ],
    [""],
    [i18n.t("exportDialog.ExtraInfo")],
    [i18n.t("exportDialog.YourRoles"), projectInfo.authorRole],
    [i18n.t("exportDialog.NameOfRWSProcess"), projectInfo.processName],
    [i18n.t("exportDialog.StartDate"), projectInfo.startDate],
    [""],
    [
      toText(
        i18n.t(
          "sectionQuestion.sectionQuestion1.title",
          structure.sectionQuestion[1]["name"]
        )
      ),
      toText(sectionQuestion[1]),
    ],
    [
      toText(
        i18n.t(
          "sectionQuestion.sectionQuestion3.title",
          structure.sectionQuestion[3]["name"]
        )
      ),
      toText(sectionQuestion[3]),
    ],
    [
      toText(
        i18n.t(
          "sectionQuestion.sectionQuestion4.title",
          structure.sectionQuestion[4]["name"]
        )
      ),
      toText(sectionQuestion[4]),
    ],
    [
      toText(
        i18n.t(
          "sectionQuestion.sectionQuestion5.title",
          structure.sectionQuestion[5]["name"]
        )
      ),
      toText(sectionQuestion[5]),
    ],
  ]);

  // Styling
  ws.getColumn(1).width = 27;
  ws.getColumn(2).width = 40;

  setTextWrap(ws.getCell("A5"));
  setTextWrap(ws.getCell("B5"));
  setTextWrap(ws.getCell("A9"));
  setTextWrap(ws.getCell("A12"));
  setTextWrap(ws.getCell("A13"));
  setTextWrap(ws.getCell("A14"));
  setTextWrap(ws.getCell("A15"));
  setTextWrap(ws.getCell("B12"));
  setTextWrap(ws.getCell("B13"));
  setTextWrap(ws.getCell("B14"));
  setTextWrap(ws.getCell("B15"));

  // Themes
  Object.values(structure.theme).forEach((theme) => {
    const ws = wb.addWorksheet(
      i18n.t(`theme.theme${theme.id}.title`, theme.name)
    );

    ws.insertRow(1, [
      i18n.t("exportDialog.Onderdeel"),
      i18n.t("exportDialog.Question"),
      i18n.t("exportDialog.SubQuestion"),
      i18n.t("exportDialog.Score"),
      i18n.t("exportDialog.Answer"),
      i18n.t("exportDialog.Explanation"),
    ]);
    let currentRowPos = 2;

    // Topics
    theme.topics.forEach((topicId) => {
      const topic = structure.topic[topicId];
      const topicStatus = StatusHelper.getTopicStatus(
        projectData.topicStatus,
        topicId
      );

      // Questions
      topic.topicQuestions.forEach((topicQuestionId, index) => {
        const topicQuestion = structure.topicQuestion[topicQuestionId];
        const question = toText(topicQuestion.question);
        const answer = toText(projectData.topicQuestion[topicQuestionId]);
        const statusColor = StatusHelper.getChartColorHex(
          projectData.topicStatus,
          topicId
        );

        ws.insertRow(currentRowPos, [
          index === 0 ? i18n.t(`topics.topic${topicId}.title`, topic.name) : "",
          index === 0
            ? i18n.t(
                `topicQuestions.topic${topicQuestionId}.question`,
                question
              )
            : "",
          index === 0
            ? ""
            : i18n.t(
                `topicQuestions.topic${topicQuestionId}.question`,
                question
              ),
          index === 0
            ? i18n.t(
                `topicStatus.status${topicStatus.id}.description`,
                topicStatus.name
              )
            : "",
          answer,
          index === 0
            ? i18n.t(`topics.topic${topicId}.description`, topic.description)
            : "",
        ]);

        // Styling
        setTextWrap(ws.getCell(currentRowPos, 2));
        setTextWrap(ws.getCell(currentRowPos, 3));
        setTextWrap(ws.getCell(currentRowPos, 5));
        setTextWrap(ws.getCell(currentRowPos, 6));

        // Status color
        if (index === 0 && statusColor !== "#fff" && statusColor.length === 7) {
          ws.getCell(currentRowPos, 4).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FF" + statusColor.slice(1) },
          };
        }

        currentRowPos++;
      });
    });

    // Styling
    ws.getColumn(1).width = 30;
    ws.getColumn(2).width = 40;
    ws.getColumn(3).width = 40;
    ws.getColumn(4).width = 35;
    ws.getColumn(5).width = 40;
    ws.getColumn(6).width = 40;
  });

  // Reflectie
  ws = wb.addWorksheet(i18n.t("section.reflectie", structure.section[3].name));
  let currentRowPos = 1;

  structure.section[3].sectionQuestions.forEach((sectionQuestionId) => {
    const answer = projectData.sectionQuestion[sectionQuestionId] ?? undefined;

    ws.insertRow(currentRowPos, [
      toText(
        i18n.t(
          `sectionQuestion.sectionQuestion${sectionQuestionId}.title`,
          structure.sectionQuestion[sectionQuestionId].name
        )
      ),
      toText(answer),
    ]);

    // Styling
    setTextWrap(ws.getCell(currentRowPos, 1));
    setTextWrap(ws.getCell(currentRowPos, 2));

    currentRowPos++;
  });

  // Styling
  ws.getColumn(1).width = 40;
  ws.getColumn(2).width = 40;

  return wb.xlsx.writeBuffer();
};

const toText = (html) => {
  const text = htmlToText(html, {
    wordwrap: false,
  });

  return text.trim();
};

const setTextWrap = (cell) => {
  cell.alignment = { wrapText: true, vertical: "top" };
};

export default connect(mapStateForExport)(Excel);
