import {combineReducers} from 'redux';

import metaData from './metaData';
import projectData from './projectData';
import structure from './structure';
import appData from './appData';

export default combineReducers({
    metaData,
    projectData,
    structure,
    app: appData,
})
