import React from "react";
import { connect, useSelector } from "react-redux";
import { Collapse } from "react-bootstrap";

import { mapSection1Dispatch, mapSection1State } from "./mapping";
import Header from "../Header";
import FormField from "../../global/FormField";
import SectionQuestion from "../../../containers/srl/_sectionQuestion/SectionQuestion";
import Language from "../../../helpers/language";
import { generatePath, Link, useRouteMatch } from "react-router-dom";
import moment from "moment";
import "moment/locale/nl";
import isEmail from "sane-email-validation";
import apiConnector from "../../../helpers/apiConnector";
import ScrollToTop from "../../global/ScrollToTop";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { Trans, useTranslation } from "react-i18next";

const Section1 = ({
  projectInfo,
  sectionQuestionsRequired,
  sectionQuestionsOptional,
  setProjectInfo,
  toggleAllowContact,
}) => {
  const { i18n } = useTranslation();
  const { appId, lang } = useSelector((state) => state.app);
  const { t } = useTranslation();
  const { path } = useRouteMatch();
  const handleProjectInfoChange = (name, value) =>
    setProjectInfo({ [name]: value });
  const handleSubmit = (e) => e.preventDefault();
  const sendContactInfo = () => {
    const formData = {
      name: projectInfo.projectName,
      contactName: projectInfo.author,
      contactPhone: projectInfo.authorPhone,
      contactEmail: projectInfo.authorEmail,
    };
    apiConnector
      .connect("post", "project-information", false, formData)
      .then((result) => {
        setProjectInfo({
          contactInfoSend: true,
          contactInfoSendAt: moment().unix(),
        });
      });
  };

  moment().locale(lang);

  return (
    <>
      <ScrollToTop />
      <Header sectionId={1} />
      <div className="row">
        <div className="col-md-8">
          <p>
            <Trans i18nKey="appSection1.Introtext">
              Stap 1 van de SRL-tool is het invullen van uw projectinformatie.
              Beantwoord onderstaande vragen zo duidelijk en concreet mogelijk.
            </Trans>
          </p>

          <form onSubmit={handleSubmit} autoComplete="off">
            <fieldset className="mb-3">
              <h2>
                <Trans i18nKey="appSection1.Subtitle">Basisinformatie</Trans>
              </h2>
              <p>
                <Trans i18nKey="appSection1.Mandatoryfields">
                  * verplichte velden
                </Trans>
              </p>

              <FormField
                handleChange={handleProjectInfoChange}
                label={
                  t(Language.getTranslation("projectName", "section1")) + "*"
                }
                required
                type="text"
                name="projectName"
                value={projectInfo.projectName}
              />
              <FormField
                handleChange={handleProjectInfoChange}
                label={t(Language.getTranslation("author", "section1")) + "*"}
                required
                type="text"
                name="author"
                value={projectInfo.author}
              />
              <FormField
                handleChange={handleProjectInfoChange}
                label={t(Language.getTranslation("currentDate", "section1"))}
                type="date"
                name="currentDate"
                value={projectInfo.currentDate}
              />

              <div className="pt-3">
                <FormField
                  disabled={
                    projectInfo.projectName === "" || projectInfo.author === ""
                  }
                  handleChange={toggleAllowContact}
                  label={t(Language.getTranslation("allowContact", "section1"))}
                  helpText={`<p>${t("appSection1.allowContactHelpText")}</p>`}
                  type="checkbox"
                  name="allowContact"
                  value={projectInfo.allowContact}
                />
                <Collapse in={projectInfo.allowContact}>
                  <div className="card">
                    <div className="card-body bg-light">
                      <div className="row">
                        <div className="col">
                          <FormField
                            disabled={projectInfo.contactInfoSend === true}
                            handleChange={handleProjectInfoChange}
                            label={t("appSection1.email")}
                            type="email"
                            name="authorEmail"
                            value={projectInfo.authorEmail}
                          />
                        </div>
                        <div className="col">
                          <FormField
                            disabled={projectInfo.contactInfoSend === true}
                            handleChange={handleProjectInfoChange}
                            label={t("appSection1.PhoneNumber")}
                            type="tel"
                            name="authorPhone"
                            value={projectInfo.authorPhone}
                          />
                        </div>
                      </div>
                      <button
                        type="button"
                        className="btn btn-primary"
                        disabled={
                          !isEmail(projectInfo.authorEmail) ||
                          projectInfo.authorPhone.length < 10 ||
                          projectInfo.contactInfoSend === true
                        }
                        onClick={sendContactInfo}
                      >
                        <Trans i18nKey="VerstuurGegevens">
                          {Language.getTranslation(
                            "sendContactInfoBtn",
                            "section1"
                          )}
                        </Trans>
                      </button>
                      {projectInfo.contactInfoSend && (
                        <span className="text-muted pl-3 font-italic">
                          <trans i18nKey="">Gegevens zijn verstuurd op </trans>
                          {moment
                            .unix(projectInfo.contactInfoSendAt)
                            .format("LLLL")}
                        </span>
                      )}
                    </div>
                  </div>
                </Collapse>
              </div>

              {sectionQuestionsRequired.length > 0 && (
                <fieldset>
                  {sectionQuestionsRequired.map((id) => (
                    <SectionQuestion questionId={id} key={id} />
                  ))}
                </fieldset>
              )}
            </fieldset>

            <Accordion className="mb-4">
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="0">
                    <span>
                      <Trans>
                        {Language.getTranslation("headingExtra", "section1")}
                      </Trans>
                      &nbsp;
                    </span>
                    <small className="text-body">
                      <Trans>
                        {Language.getTranslation(
                          "headingExtraDescription",
                          "section1"
                        )}
                      </Trans>
                    </small>
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <fieldset className="mb-3">
                      <FormField
                        handleChange={handleProjectInfoChange}
                        label={t(
                          Language.getTranslation("authorRole", "section1")
                        )}
                        type="text"
                        name="authorRole"
                        value={projectInfo.authorRole}
                      />
                      {appId === "rws-2020-nl" && (
                        <FormField
                          handleChange={handleProjectInfoChange}
                          label={t(
                            Language.getTranslation("processName", "section1")
                          )}
                          type="select"
                          name="processName"
                          value={projectInfo.processName}
                          options={[
                            i18n.t("processNames.crisismanagement"),
                            i18n.t("processNames.knowledge"),
                            i18n.t("processNames.environment"),
                            i18n.t("processNames.construction"),
                            i18n.t("processNames.traffic"),
                            i18n.t("processNames.info"),
                            i18n.t("processNames.business"),
                          ]}
                        />
                      )}
                      <FormField
                        handleChange={handleProjectInfoChange}
                        label={t(
                          Language.getTranslation("startDate", "section1")
                        )}
                        type="date"
                        name="startDate"
                        value={projectInfo.startDate}
                      />
                    </fieldset>

                    {sectionQuestionsOptional.length > 0 && (
                      <fieldset>
                        {sectionQuestionsOptional.map((id) => (
                          <SectionQuestion questionId={id} key={id} />
                        ))}
                      </fieldset>
                    )}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>

            <fieldset className="mb-4">
              <Link
                to={generatePath(path, { step: 2 })}
                className="btn btn-next btn-warning"
              >
                <Trans i18nKey="Nextstep">
                  {Language.getTranslation("nextStep")}
                </Trans>
              </Link>
            </fieldset>
          </form>
        </div>
      </div>
    </>
  );
};

export default connect(mapSection1State, mapSection1Dispatch)(Section1);
