import { generateStateHash } from "../../../helpers/store";
import rws_2020_nl from "./rws-2020-nl/initialState";

export default function getInitialState(appId) {
  let initialState;

  switch (appId) {
    case "rws-2020-nl":
    default:
      initialState = rws_2020_nl;
      break;
  }

  if (initialState.hasOwnProperty("app")) {
    initialState.app.hash = generateStateHash(initialState);
  }

  return initialState;
}
