import React from "react";
import {
  Document,
  Font,
  Image,
  Page,
  PDFDownloadLink,
  PDFViewer,
  Text,
  View,
} from "@react-pdf/renderer";
import { connect } from "react-redux";

import { mapStateForExport } from "./mapping";
import OverviewChart from "../srl/_section2/OverviewChart";
import { StatusHelper } from "../../helpers/statusHelper";
import Colors from "../../css/export-theme-for-react.scss";
import ThemeChart from "../../components/srl/_section2/ThemeChart";
import RichText from "./RichText";
import styles, { spacer } from "./styles";
import logo from "./images/logo";
import { ExportHelper } from "./ExportHelper";
import icons from "./images/icons";
import { Trans, useTranslation } from "react-i18next";

const Pdf = ({ mode, state, showLogo }) => {
  const { t } = useTranslation();

  // Create Refs
  const chartRef = React.createRef();
  const themeChartRefs = [];

  Object.values(state.structure.theme).forEach((theme) => {
    themeChartRefs[theme.urlSlug] = React.createRef();
  });

  // PDF filename
  const filename = ExportHelper.createFilename(
    state.projectData.projectInfo.projectName
  );

  return (
    <>
      <div className="sr-only">
        <OverviewChart chartRef={chartRef} />
      </div>

      {Object.values(state.structure.theme).map((theme) => {
        return (
          <div className="sr-only" key={`themeChart${theme.id}`}>
            <ThemeChart
              activeTheme={theme.urlSlug}
              chartRef={themeChartRefs[theme.urlSlug]}
            />
          </div>
        );
      })}

      {mode === "screen" ? (
        <PDFViewer className="w-100" style={{ height: 1200 }}>
          document=
          {
            <PdfDocument
              showLogo={showLogo}
              chartRef={chartRef}
              state={state}
              themeChartRefs={themeChartRefs}
            />
          }
        </PDFViewer>
      ) : (
        <PDFDownloadLink
          document={
            <PdfDocument
              showLogo={showLogo}
              chartRef={chartRef}
              state={state}
              themeChartRefs={themeChartRefs}
            />
          }
          fileName={filename}
          className="btn btn-lg btn-warning"
        >
          {({ blob, url, loading, error }) =>
            loading
              ? t("exportDialog.loadingPdfExport", "Bezig met aanmaken PDF...")
              : t("exportDialog.downloadFileBtn", "Bestand downloaden")
          }
        </PDFDownloadLink>
      )}
    </>
  );
};

// Register font
Font.register({
  family: "ROSans",
  fonts: [
    { src: "/webfonts/ROsanswebtextregular.ttf" },
    { src: "/webfonts/ROsanswebtextitalic.ttf", fontStyle: "italic" },
    { src: "/webfonts/ROsanswebtextbold.ttf", fontWeight: 700 },
  ],
});

const PdfDocument = ({ chartRef, state, themeChartRefs, showLogo }) => {
  const { projectData, structure } = state;
  const projectInfo = projectData.projectInfo;
  const sectionQuestion = projectData.sectionQuestion;

  // Update and create Chart images
  const chartImages = [];
  const chart = chartRef.current.chartInstance;

  chart.options.maintainAspectRatio = false;
  chart.canvas.parentNode.style.height = "500px";
  chart.canvas.parentNode.style.width = "500px";
  chart.update();
  chart.resize();

  chartImages["overview"] = chart.toBase64Image();

  Object.values(structure.theme).forEach((theme) => {
    const chart = themeChartRefs[theme.urlSlug].current.chartInstance;
    chart.options.maintainAspectRatio = false;
    chart.canvas.parentNode.style.height = "500px";
    chart.canvas.parentNode.style.width = "500px";
    chart.update();
    chart.resize();

    chartImages[theme.urlSlug] = chart.toBase64Image();
  });

  return (
    <Document>
      <Page size="A4" style={[styles.page, { paddingTop: 0 }]}>
        {showLogo ? (
          <Image src={{ uri: logo }} />
        ) : (
          <View style={{ marginBottom: 24 }}></View>
        )}

        <View style={styles.section}>
          <Text style={styles.h1}>Stakeholder Readiness Level</Text>
          <Text style={[styles.text, styles.mb3, styles.small]}>
            https://{window.location.hostname}
          </Text>
          <Text style={styles.h2}>
            <Trans i18nKey="pdf.Part1">Deel 1: Projectinformatie</Trans>
          </Text>
          <Text style={styles.text} />
          <Text style={styles.h3}>{projectInfo.projectName}</Text>
        </View>

        <View style={styles.section}>
          <View style={styles.tr}>
            <View style={[styles.td, styles.label]}>
              <Text>
                <Trans i18nKey="pdf.NameOfInnovation">
                  Naam van de innovatie:
                </Trans>
              </Text>
            </View>
            <View style={styles.td}>
              <Text>{projectInfo.projectName}</Text>
            </View>
          </View>
          <View style={styles.tr}>
            <View style={[styles.td, styles.label]}>
              <Text>
                <Trans i18nKey="pdf.NameOfFillers">Naam invuller(s):</Trans>
              </Text>
            </View>
            <View style={styles.td}>
              <Text>{projectInfo.author}</Text>
            </View>
          </View>
          <View style={styles.tr}>
            <View style={[styles.td, styles.label]}>
              <Text>
                <Trans i18nKey="pdf.DateOfToday">Datum vandaag:</Trans>
              </Text>
            </View>
            <View style={styles.td}>
              <Text>{projectInfo.currentDate}</Text>
            </View>
          </View>
        </View>

        <View>
          <Text style={styles.h3}>
            <Trans i18nKey="pdf.HowFar">
              Hoe ver is de innovatie qua ontwikkeling?
            </Trans>
          </Text>
          <RichText html={sectionQuestion[2]} />
        </View>

        <View style={[styles.section, styles.mb4]}>
          <Text style={styles.h2}>
            <Trans i18nKey="pdf.ExtraInfo">Extra informatie</Trans>
          </Text>

          <View style={styles.tr}>
            <View style={[styles.td, styles.label]}>
              <Text>
                <Trans i18nKey="pdf.YourRoles">
                  Uw rol(len) in deze innovatie:
                </Trans>
              </Text>
            </View>
            <View style={styles.td}>
              <Text>{projectInfo.authorRole}</Text>
            </View>
          </View>
          <View style={styles.tr}>
            <View style={[styles.td, styles.label]}>
              <Text>
                <Trans i18nKey="pdf.NameOfRWSProcess">
                  Naam van Rijkswaterstaatproces waar deze innovatie onder valt:
                </Trans>
              </Text>
            </View>
            <View style={styles.td}>
              <Text style={{ marginTop: spacer }}>
                {projectInfo.processName}
              </Text>
            </View>
          </View>
          <View style={styles.tr}>
            <View style={[styles.td, styles.label]}>
              <Text>
                <Trans i18nKey="pdf.StartDate">Startdatum:</Trans>
              </Text>
            </View>
            <View style={styles.td}>
              <Text>{projectInfo.startDate}</Text>
            </View>
          </View>
        </View>

        <View>
          <Text style={styles.h3}>
            <Trans i18nKey="pdf.InnovationGoals">
              Wat is de innovatie en wat wordt er met de innovatie beoogd? Aan
              welke RWS-doelen en IenW-doelen draagt de innovatie bij?
            </Trans>
          </Text>
          <RichText html={sectionQuestion[1]} />
        </View>
        <View>
          <Text style={styles.h3}>
            <Trans i18nKey="pdf.Initiative">
              Waar komt het initiatief vandaan?
            </Trans>
          </Text>
          <RichText html={sectionQuestion[3]} />
        </View>
        <View>
          <Text style={styles.h3}>
            <Trans i18nKey="pdf.InnovationProvider">
              Omschrijving innovatieaanbieder: aard organisatie, omvang, positie
              in de markt, met wie wordt er samengewerkt (indien van
              toepassing)?
            </Trans>
          </Text>
          <RichText html={sectionQuestion[4]} />
        </View>
        <View>
          <Text style={styles.h3}>
            <Trans i18nKey="pdf.Timeline">
              Hoe ziet het tijdpad van deze innovatie eruit?
            </Trans>
          </Text>
          <RichText html={sectionQuestion[5]} />
        </View>

        <PageFooter />
      </Page>

      <Page size="A4" style={styles.page}>
        <Text style={styles.h2}>
          <Trans i18nKey="pdf.Part2">Deel 2: SRL-vragen</Trans>
        </Text>
        <Text style={styles.h4}>
          <Trans i18nKey="pdf.Overview">Overzicht</Trans>
        </Text>

        <Image
          src={{ uri: chartImages["overview"] }}
          style={styles.chartOverview}
        />

        {Object.values(structure.theme).map((theme) => {
          return (
            <Text
              style={[styles.icon, styles[`icon${theme.id}`]]}
              key={`themeIcon${theme.id}`}
            >
              <Trans i18nKey={`theme.theme${theme.id}.title`}>
                {theme.name}
              </Trans>
            </Text>
          );
        })}
        {Object.values(structure.theme).map((theme) => {
          return (
            <Image
              src={{ uri: icons[theme.icon] }}
              style={[styles.chartOverviewIcon, styles[`iconImage${theme.id}`]]}
              key={`themeIconImage${theme.id}`}
            />
          );
        })}

        <View style={{ marginTop: spacer }}>
          <LegendList topicStatusData={Object.values(structure.topicStatus)} />
        </View>

        <PageFooter />
      </Page>

      {Object.values(structure.theme).map((theme) => {
        return (
          <Page size="A4" style={styles.page} key={`themePage${theme.id}`}>
            <Text style={styles.h4}>
              <Trans i18nKey={`theme.theme${theme.id}.title`}>
                {theme.name}
              </Trans>
            </Text>
            <Text style={styles.mb4}>
              <Trans i18nKey={`theme.theme${theme.id}.description`}>
                {theme.description}
              </Trans>
            </Text>

            <View style={[styles.row, styles.mb4]}>
              <View style={styles.col}>
                <Image
                  src={{ uri: chartImages[theme.urlSlug] }}
                  style={{ width: 175, height: 175 }}
                />
              </View>
              <View style={[styles.col, { marginTop: spacer }]}>
                <LegendList
                  topicStatusData={Object.values(structure.topicStatus)}
                />
              </View>
            </View>

            {theme.topics.map((topicId) => {
              const topic = structure.topic[topicId];
              const topicStatus = StatusHelper.getTopicStatus(
                projectData.topicStatus,
                topicId
              );
              const topicColor = StatusHelper.getChartColorHex(
                projectData.topicStatus,
                topicId
              );

              return (
                <View
                  style={styles.mb3}
                  key={`themePage${theme.id}Topic${topicId}`}
                >
                  <View style={styles.row} wrap={false}>
                    <Text
                      style={[
                        styles.topicStatus,
                        {
                          backgroundColor: topicColor,
                          border:
                            topicColor === "#fff" ? "1pt solid #bbb" : "none",
                        },
                      ]}
                    />
                    <View style={[styles.mb3, styles.col]}>
                      <Text>
                        <Trans i18nKey={`topics.topic${topic.id}.title`}>
                          {topic.name}
                        </Trans>
                      </Text>
                      <Text style={{ fontStyle: "italic" }}>
                        Status:{" "}
                        <Trans i18nKey={topicStatus.name}>
                          {topicStatus.name}
                        </Trans>
                      </Text>
                    </View>
                  </View>

                  {topic.topicQuestions.map((topicQuestionId) => {
                    const topicQuestion =
                      structure.topicQuestion[topicQuestionId];

                    return (
                      <View
                        key={`themePage${theme.id}Topic${topicId}Question${topicQuestionId}`}
                        wrap={false}
                      >
                        <Text style={styles.h3}>
                          <Trans
                            i18nKey={`topicQuestions.topic${topicQuestionId}.question`}
                          >
                            {topicQuestion.question
                              ? topicQuestion.question
                              : ""}
                          </Trans>
                        </Text>
                        <RichText
                          html={
                            projectData.topicQuestion[topicQuestionId] ??
                            undefined
                          }
                        />
                      </View>
                    );
                  })}
                </View>
              );
            })}

            <PageFooter />
          </Page>
        );
      })}

      <Page size="A4" style={styles.page}>
        <Text style={[styles.h2, styles.mb4]}>
          <Trans i18nKey="pdf.Part3">Deel 3: Reflectie</Trans>
        </Text>

        {structure.section[3].sectionQuestions.map((sectionQuestionId) => {
          const sectionQuestion = structure.sectionQuestion[sectionQuestionId];
          const answer =
            projectData.sectionQuestion[sectionQuestionId] ?? undefined;

          return (
            <View key={`sectionQuestion${sectionQuestionId}`}>
              <Text style={styles.h3}>
                <Trans
                  i18nKey={`sectionQuestion.sectionQuestion${sectionQuestionId}.title`}
                >
                  {sectionQuestion.name}
                </Trans>
              </Text>
              <RichText html={answer} />
            </View>
          );
        })}

        <PageFooter />
      </Page>
    </Document>
  );
};

const PageFooter = () => {
  const { i18n } = useTranslation();
  return (
    <Text
      style={[styles.pageNumbers, styles.small]}
      render={({ pageNumber, totalPages }) =>
        `${i18n.t("pdf.page")} ${pageNumber} ${i18n.t(
          "pdf.pageOf"
        )} ${totalPages}`
      }
      fixed
    />
  );
};

const LegendList = ({ topicStatusData = [] }) => {
  return topicStatusData.map((item) => {
    const statusColorHex = Colors[item.color];
    const border = item.id === 1 ? "1pt solid #bbb" : "none";

    return (
      <View
        style={[styles.row, { marginBottom: spacer / 2 }]}
        wrap={false}
        key={`legend-item-${item.id}`}
      >
        <Text
          style={[
            styles.circle,
            {
              border: border,
              backgroundColor: statusColorHex,
            },
          ]}
        />
        <Text style={styles.col}>
          <Trans i18nKey={`topicStatus.status${item.id}.description`}>
            {item.name}
          </Trans>
        </Text>
      </View>
    );
  });
};

export default connect(mapStateForExport)(Pdf);
