import React from "react";
import { Route, Switch, useParams, useRouteMatch } from "react-router-dom";
import { connect } from "react-redux";

import Header from "../../../components/srl/Header";
import ThemeNav from "./ThemeNav";
import Overview from "./Overview";
import Theme from "./Theme";
import { mapThemeNavState } from "./mapping";

const Section2 = () => {
  let { path } = useRouteMatch();

  return (
    <>
      <Header sectionId={2} />
      <Switch>
        <Route path={`${path}/:activeTheme?`} component={SwitchTheme} />
      </Switch>
    </>
  );
};

const SwitchTheme = () => {
  let { activeTheme } = useParams();
  const ThemeNavRedux = connect(mapThemeNavState)(ThemeNav);

  return (
    <>
      <ThemeNavRedux
        activeThemeUrlSlug={activeTheme === undefined ? "" : activeTheme}
      />
      {activeTheme === undefined ? <Overview /> : <Theme />}
    </>
  );
};

export default Section2;
