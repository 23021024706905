import React, { useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";

import "./css/main.scss";
import Language from "./helpers/language/";
import Routes from "./containers/Routes";
import Header from "./components/global/Header";
import Navbar from "./components/global/Navbar";
import Footer from "./components/global/Footer";
import createStore from "./helpers/store";
import { Provider } from "react-redux";
import setLanguage from "./i18n";

export default function App() {
  const lang = Language.getLanguage();
  const [language, setLanguage] = useState(null);

  useEffect(() => {
    if (lang) {
      setLanguage(lang);
    }
  }, [lang]);

  return (
    <Router>
      {getDataset(window.location.hostname, language, setLanguage, lang)}
      <Footer />
    </Router>
  );
}

function getDataset(domain, language, setLanguage, lang) {
  if (lang !== null) {
    return (
      <DefaultApp
        setLanguage={setLanguage}
        getLanguage={language}
        appId={`rws-2020-${language}`}
        lang={language}
      />
    );
  }
  switch (domain) {
    case "localhost.srl-tool.com":
    case "staging.srl-tool.com":
    case "srl-tool.com":
      if (!language) {
        Language.setLanguage("en");
        setLanguage("en");
      }
      break;

    case "localhost.srl-tool.nl":
    case "staging.srl-tool.nl":
    case "srl-tool.nl":
    default:
      if (!language) {
        Language.setLanguage("nl");
        setLanguage("nl");
      }
  }
}

function DefaultApp(props) {
  const lang = Language.getLanguage();
  if (!props.appId) {
    return null;
  }
  const store = createStore(`rws-2020-${lang}`, lang);

  setLanguage(`rws-2020-${lang}`, lang);

  document.title = Language.getTranslation("siteName");
  document.documentElement.lang = lang;

  return (
    <Provider store={store}>
      <Header {...props} />
      <Navbar {...props} />
      <Routes {...props} />
    </Provider>
  );
}
