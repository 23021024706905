import {RESET_STATE, UPDATE_APP_DATA} from "../actions";

const appData = (state = {}, {type, payload}) => {
    switch (type) {
        case RESET_STATE:
            return payload.app;

        case UPDATE_APP_DATA:
            return {
                ...state,
                ...payload,
            };
        default:
            return state;
    }
};

export default appData;
