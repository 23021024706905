import React from 'react';

import Language from "../../helpers/language/";

const Header = () => {
    return (
        <header className="rws-logo">
            <div className="bg-main-lighter">
                <div className="container">
                    <div className="site-title">{Language.getTranslation('brand')}</div>
                </div>
            </div>
        </header>
    );
}

export default Header;