import React from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import Pdf from "./Pdf";

const Export = () => {
    return (
        <div role="main" className="container">
            <Switch>
                <Route path="/export/" exact render={() => <Redirect to="/" />} />
                <Route path="/export/pdf" component={Pdf} />
                <Route path="/export/pdf/screen" component={Pdf} />
            </Switch>
        </div>
    );
};

export default Export;
