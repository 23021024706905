import { v4 as uuidv4 } from "uuid";
import moment from "moment";

const initialState = {
  app: {
    changesSaved: undefined,
    hash: "",
    appId: "rws-2020-nl",
    lang: "nl",
  },
  metaData: {
    lastOpenedPage: "",
    projectId: uuidv4(),
  },
  projectData: {
    projectInfo: {
      projectName: "",
      author: "",
      authorRole: "",
      currentDate: moment().format("Y-M-D"),
      startDate: "",
      processName: "",
      allowContact: false,
      contactInfoSend: false,
      contactInfoSendAt: 0,
      authorEmail: "",
      authorPhone: "",
    },
    sectionQuestion: {},
    topicQuestion: {},
    topicStatus: {},
  },
  structure: {
    sections: [1, 2, 3],
    section: {
      1: {
        id: 1,
        name: "Start",
        sectionQuestions: [2],
        urlSlug: "projectinformatie",
      },
      2: {
        id: 2,
        name: "SRL-vragen",
        themes: [1, 2, 3, 4, 5],
        urlSlug: "srlvragen",
      },
      3: {
        id: 3,
        name: "Reflectie",
        sectionQuestions: [6, 7, 8],
        urlSlug: "reflectie",
      },
      4: {
        id: 4,
        name: "Projectinformatie optional",
        sectionQuestions: [1, 3, 4, 5],
        urlSlug: "projectinformatieOptional",
      },
    },
    sectionQuestion: {
      1: {
        id: 1,
        name: "Wat is de innovatie en wat wordt er met de innovatie beoogd? Aan welke RWS-doelen en IenW-doelen draagt de innovatie bij?",
        helpText:
          "<ul><li>Toepassing bij welke objecten of processen van Rijkswaterstaat?</li></ul>",
        useSmallWysiwyg: true,
        urlSlug: "sectionQuestion1",
        type: "text",
        section: 1,
        required: false,
      },
      2: {
        id: 2,
        name: 'Hoe ver is de innovatie qua ontwikkeling (<a href="https://rwsinnoveert.nl/uitleg-trl/uitleg-trl/" class="external-link" target="_blank" rel="noopener noreferrer">TRL</a>)?',
        helpText:
          "<ul><li>Zijn hier onderbouwende documenten voor beschikbaar bijvoorbeeld?</li><li>Is de innovatie al eerder toegepast? Zo ja, waar en wanneer?</li></ul>",
        useSmallWysiwyg: true,
        urlSlug: "sectionQuestion2",
        type: "text",
        section: 1,
        required: true,
      },
      3: {
        id: 3,
        name: "Waar komt het initiatief vandaan?",
        helpText:
          "<ul><li>Bij marktpartijen, georganiseerde burgers, kennisinstellingen, overheden (of een combinatie)? Is het aanbodgedreven of vraaggestuurd?</li></ul>",
        useSmallWysiwyg: true,
        urlSlug: "sectionQuestion3",
        type: "text",
        section: 1,
        required: false,
      },
      4: {
        id: 4,
        name: "Omschrijving innovatieaanbieder: aard organisatie, omvang, positie in de markt, met wie wordt er samengewerkt (indien van toepassing)?",
        helpText:
          "<ul><li>Ondernemersfitheid innovator: is de innovator in staat en heeft hij de competenties om de innovatie marktrijp te maken? <li>Welke inzet komt er hiervoor van de innovator?</li><li>Kan marktordening belemmerend werken bij ondernemersfitheid?</li></ul>",
        useSmallWysiwyg: true,
        urlSlug: "sectionQuestion4",
        type: "text",
        section: 1,
        required: false,
      },
      5: {
        id: 5,
        name: "Hoe ziet het tijdpad van deze innovatie eruit?",
        helpText:
          "<ul><li>Wanneer is ermee gestart?</li><li>Wanneer is de innovatie volgens planning klaar voor toepassing?</li></ul>",
        useSmallWysiwyg: true,
        urlSlug: "sectionQuestion5",
        type: "text",
        section: 1,
        required: false,
      },
      6: {
        id: 6,
        name: "Zijn er andere belemmeringen/uitdagingen die hiervoor nog niet aan de orde zijn geweest?",
        helpText: "",
        useSmallWysiwyg: true,
        urlSlug: "sectionQuestion6",
        type: "text",
        section: 3,
        required: false,
      },
      7: {
        id: 7,
        name: "Wat is uw grootste eigen zorg of uitdaging?",
        helpText: "",
        useSmallWysiwyg: true,
        urlSlug: "sectionQuestion7",
        type: "text",
        section: 3,
        required: false,
      },
      8: {
        id: 8,
        name: "Wat zijn de belangrijkste acties die u na het invullen van deze tool gaat ondernemen?",
        helpText: "",
        useSmallWysiwyg: true,
        urlSlug: "sectionQuestion8",
        type: "text",
        section: 3,
        required: false,
      },
    },
    theme: {
      1: {
        id: 1,
        name: "Waarde",
        description: "De meerwaarde van de innovatie voor Rijkswaterstaat",
        icon: "addedValue",
        urlSlug: "waarde",
        topics: [1, 2, 3, 4],
      },
      2: {
        id: 2,
        name: "Commitment en Draagvlak",
        description:
          "Het enthousiasme en commitment binnen de organisatie voor de innovatie en de bereidheid om de innovatie toe te passen.",
        icon: "supportbase",
        topics: [5, 6, 7, 8, 9, 10],
        urlSlug: "commitment-en-draagvlak",
      },
      3: {
        id: 3,
        name: "Kosten",
        description:
          "De (meer)kosten die met de innovatie gemoeid zijn (voor de ontwikkeling en toepassing) en de dekking van de kosten",
        icon: "cost",
        topics: [11, 12, 13, 14, 15],
        urlSlug: "kosten",
      },
      4: {
        id: 4,
        name: "Inpasbaarheid",
        description:
          "De mate van aanpassing die nodig is om de innovatie in te passen in bestaande (werk)processen en/of om processen aan te passen",
        icon: "suitability",
        topics: [16, 17, 18, 19, 20, 21],
        urlSlug: "inpasbaarheid",
      },
      5: {
        id: 5,
        name: "Risico's",
        description:
          "De risico’s waar rekening mee gehouden moet worden bij de toepassing van de innovatie",
        icon: "risks",
        topics: [22, 23, 24, 25, 26, 27, 28, 29, 30],
        urlSlug: "risicos",
      },
    },
    topic: {
      1: {
        id: 1,
        name: "Meerwaarde voor RWS (opgaven)",
        description:
          "Draagt het bijv bij op het gebied van Duurzaamheid of circulariteit, of draagt het bij aan de productieopgave? (bijvoorbeeld assetmanagement, functionaliteit, lagere kosten). Heeft het meerwaarde ten opzichte van de huidige methoden/oplossingen? Waar zit die meerwaarde in? Is er een onderbouwing/ kwantificering beschikbaar waaruit blijkt dat deze meerwaarde significant is (in relatieve en/of absolute zin)? Is de meerwaarde besproken met de mensen die in de productieprocessen werken? Lost de innovatie een probleem op of gaat het om het aangrijpen van een kans?\n" +
          "Met oog op toekomstige toepassing:\n" +
          "zijn er voldoende geschikte projecten (in beeld) waar de innovatie toegepast kan worden? \n",
        topicQuestions: [1],
        urlSlug: "meerwaarde-voor-rws",
      },
      2: {
        id: 2,
        name: "Meerwaarde voor RWS (focuspunten)",
        description:
          "Welke concrete bijdrage kan de innovatie leveren aan de doelen van de?\n" +
          "\n" +
          "•\tAssetmanagement 2.0\n" +
          "•\tKlimaatadaptatie\n" +
          "•\tDuurzaamheid en Leefomgeving\n" +
          "•\tData en informatievoorziening\n" +
          "•\tSmart Mobility\n" +
          "•\tOpgavegericht Samenwerken \n\n" +
          "Als het een innovatie betreft die niet tot een focuspunt behoord, kleur in dat geval ‘lichtgrijs’ - niet van toepassing.",
        topicQuestions: [2],
        urlSlug: "meerwaarde-voor-rws-focuspunten",
      },
      3: {
        id: 3,
        name: "Meerwaarde voor andere gebruikers",
        description:
          "Zijn andere gebruikers (/potentiële afnemers) in beeld? Zo ja, wie zijn dat en is er meerwaarde voor deze gebruikers? Zijn andere gebruikers nodig om deze innovatie te ontwikkelen?",
        topicQuestions: [3],
        urlSlug: "meerwaarde-voor-andere-gebruikers",
      },
      4: {
        id: 4,
        name: "Generieke maatschappelijke baten",
        description:
          "Zijn de maatschappelijke waarden van deze innovatie in beeld? Is het belangrijk dat de innovatie ook andere maatschappelijke waarden heeft (bijvoorbeeld hinder, geluid, gezondheid)? ",
        topicQuestions: [4],
        urlSlug: "generieke-maatschappelijke-baten",
      },
      5: {
        id: 5,
        name: "Bestuurlijk draagvlak intern RWS",
        description:
          "Past de innovatie onder RWS-beleid, is er zicht op bestuurlijk draagvlak of is er al draagvlak op bestuurlijk niveau dat zichtbaar en merkbaar is?",
        topicQuestions: [5],
        urlSlug: "bestuurlijk-draagvlak-intern-rws",
      },
      6: {
        id: 6,
        name: "Opdrachtgever intern RWS",
        description:
          "Is er een Single Responsible Owner: een duidelijke opdrachtgever met eigenaarschap en doorzetmacht, die waar nodig belemmeringen in de organisatie kan wegnemen vanuit zijn/haar positie? (denk bijvoorbeeld aan een (programma- of lijn)manager, een directeur) Maakt de SRO voldoende tijd vrij om mee te denken over strategische keuzes?",
        topicQuestions: [6],
        urlSlug: "opdrachtgever-intern-rws",
      },
      7: {
        id: 7,
        name: "Projectleider intern RWS",
        description:
          "Is er een aangewezen projectleider of (innovatie)trekker, die ervoor zorgt dat de innovatie effectief door het totale ontwikkel- en implementatieproces loopt? Een persoon met voldoende enthousiasme, energie, doorzettingsvermogen, verbindend vermogen, geduld en geloof in de innovatie.",
        topicQuestions: [7],
        urlSlug: "projectleider-intern-rws",
      },
      8: {
        id: 8,
        name: "Condities projectleider intern RWS",
        description:
          "Is duidelijk wat er wordt verwacht van de projectleider? Speelt de projectleider een rol in het totale innovatieproces of enkel gedeeltelijk? Heeft de projectleider het nodige mandaat en middelen en mensen tot zijn/haar beschikking?",
        topicQuestions: [8],
        urlSlug: "condities-projectleider-intern-rws",
      },
      9: {
        id: 9,
        name: "Draagvlak intern RWS",
        description:
          "Is er weerstand (te verwachten) vanuit bepaalde onderdelen van de organisatie (personen, teams, afdelingen)? Bij wie ligt het, op welk niveau? Is de weerstand of hindermacht gemakkelijk weg te nemen of is het onoverkomelijk? Zijn er al acties in gang gezet om deze weg te nemen?",
        topicQuestions: [9],
        urlSlug: "draagvlak-intern-rws",
      },
      10: {
        id: 10,
        name: "Draagvlak omgeving",
        description:
          "Zijn er voorstanders van de innovatie in de omgeving (zoals bij andere stakeholders) die kunnen helpen bij het richting toepassing brengen van deze innovatie? Zijn er tegenstanders die de innovatie kunnen tegenhouden? Zo ja, hoe wordt hiermee omgegaan?",
        topicQuestions: [10],
        urlSlug: "draagvlak-omgeving",
      },
      11: {
        id: 11,
        name: "Ontwikkelkosten",
        description: null,
        topicQuestions: [11, 16, 17, 18],
        urlSlug: "ontwikkelkosten",
      },
      12: {
        id: 12,
        name: "Kosten toepassing",
        description: null,
        topicQuestions: [12, 19, 20, 21],
        urlSlug: "kosten-toepassing",
      },
      13: {
        id: 13,
        name: "Investeringsbereidheid externe partijen",
        description: null,
        topicQuestions: [13, 22, 23],
        urlSlug: "investeringsbereidheid-externe-partijen",
      },
      14: {
        id: 14,
        name: "Investeringsbereidheid RWS",
        description: null,
        topicQuestions: [14],
        urlSlug: "investeringsbereidheid-rws",
      },
      15: {
        id: 15,
        name: "Business model",
        description:
          "Is er een business model voor de verschillende investeringspartijen dat naar tevredenheid van al die partijen is, of kan dit een heikel punt worden? Zijn alle nodige kosten voor ontwikkeling en toepassing hiermee gedekt? \n\nMet oog op toekomstige toepassing: zijn er geschikte projecten (in beeld) waar de innovatie toegepast kan worden (binnen en/of buiten Rijkswaterstaat)? En op welke termijn? Staat de bereidheid van externe partners (marktpartijen en andere afnemers van de innovatie) om te investeren in de innovatie, in verhouding tot de baten (in de ruimste zin des woords) die deze partijen verwachten van de innovatie?",
        topicQuestions: [15],
        urlSlug: "business-model",
      },
      16: {
        id: 16,
        name: "Huidge wet- en regelgeving",
        description:
          "Past de innovatie binnen de huidige wet- en regelgeving of kan de wet- en regelgeving zonder al te grote problemen worden aangepast of is geregeld dat er een uitzondering kan worden gemaakt?",
        topicQuestions: [24],
        urlSlug: "huidge-wet-en-regelgeving",
      },
      17: {
        id: 17,
        name: "Kaders en richtlijnen intern RWS",
        description:
          "Past de innovatie in de huidige kaders en richtlijnen of kunnen de kaders en richtlijnen zonder al te grote problemen worden aangepast of is geregeld dat er een uitzondering kan worden gemaakt?",
        topicQuestions: [25],
        urlSlug: "kaders-en-richtlijnen-intern-rws",
      },
      18: {
        id: 18,
        name: "Inkoopstrategie RWS",
        description: "",
        topicQuestions: [26, 27, 28],
        urlSlug: "inkoopstrategie-rws",
      },
      19: {
        id: 19,
        name: "Werkprocessen RWS",
        description: "",
        topicQuestions: [29, 30, 31, 32, 33],
        urlSlug: "werkprocessen-rws",
      },
      20: {
        id: 20,
        name: "Werkprocessen aannemer",
        description:
          "Past de innovatie in de werkprocessen van de aannemer? Zijn daarbij aanpassingen nodig die te regelen zijn, voordat de aannemer de innovatie kan toepassen?",
        topicQuestions: [34],
        urlSlug: "werkprocessen-aannemer",
      },
      21: {
        id: 21,
        name: "Veranderdruk",
        description: null,
        topicQuestions: [35, 36, 37],
        urlSlug: "veranderdruk",
      },
      22: {
        id: 22,
        name: "Betrouwbaarheid",
        description: null,
        topicQuestions: [38],
        urlSlug: "reliability",
      },
      23: {
        id: 23,
        name: "Beschikbaarheid",
        description: null,
        topicQuestions: [39],
        urlSlug: "availability",
      },
      24: {
        id: 24,
        name: "Onderhoudbaarheid",
        description: null,
        topicQuestions: [40],
        urlSlug: "maintainability",
      },
      25: {
        id: 25,
        name: "Securiteit (veiligheid)",
        description: null,
        topicQuestions: [41],
        urlSlug: "security",
      },
      26: {
        id: 26,
        name: "Geborgenheid (veiligheid)",
        description: null,
        topicQuestions: [42],
        urlSlug: "safety",
      },
      27: {
        id: 27,
        name: "Gezondheid",
        description: null,
        topicQuestions: [43],
        urlSlug: "health",
      },
      28: {
        id: 28,
        name: "Milieu/omgeving",
        description: null,
        topicQuestions: [44],
        urlSlug: "environment",
      },
      29: {
        id: 29,
        name: "Economie",
        description: null,
        topicQuestions: [45],
        urlSlug: "economics",
      },
      30: {
        id: 30,
        name: "Politiek",
        description: null,
        topicQuestions: [46],
        urlSlug: "politics",
      },
    },
    topicQuestion: {
      1: {
        id: 1,
        question:
          "Heeft de innovatie significante meerwaarde voor de RWS opgaven?",
        slug: "topicQuestion1",
        useSmallWysiwyg: true,
      },
      2: {
        id: 2,
        question: "Draagt de innovatie bij aan de focuspunten van RWS?",
        slug: "topicQuestion2",
        useSmallWysiwyg: true,
      },
      3: {
        id: 3,
        question: "Heeft de innovatie ook meerwaarde voor andere gebruikers?",
        slug: "topicQuestion3",
        useSmallWysiwyg: true,
      },
      4: {
        id: 4,
        question:
          "Heb je in beeld of de innovatie maatschappelijke waarden heeft?",
        slug: "topicQuestion4",
        useSmallWysiwyg: true,
      },
      5: {
        id: 5,
        question: "Is er bestuurlijk draagvlak?",
        slug: "topicQuestion5",
        useSmallWysiwyg: true,
      },
      6: {
        id: 6,
        question:
          "Is er een opdrachtgever met voldoende doorzetmacht intern en voelt die zich ook echt sponsor?",
        slug: "topicQuestion6",
        useSmallWysiwyg: true,
      },
      7: {
        id: 7,
        question:
          "Is er een gedreven interne projectleider met voldoende tijd?",
        slug: "topicQuestion7",
        useSmallWysiwyg: true,
      },
      8: {
        id: 8,
        question:
          "Heeft de projectleider een duidelijke opdracht, met bijbehorend mandaat?",
        slug: "topicQuestion8",
        useSmallWysiwyg: true,
      },
      9: {
        id: 9,
        question:
          "Is er voldoende draagvlak voor de innovatie binnen de organisatie?",
        slug: "topicQuestion9",
        useSmallWysiwyg: true,
      },
      10: {
        id: 10,
        question: "Is er voldoende draagvlak voor de innovatie in de omgeving?",
        slug: "topicQuestion10",
        useSmallWysiwyg: true,
      },
      11: {
        id: 11,
        question:
          "Heb je in beeld wat de kosten zijn voor RWS verbonden aan de ontwikkeling van deze innovatie?",
        slug: "topicQuestion11",
      },
      12: {
        id: 12,
        question:
          "Heb je in beeld wat de kosten zijn voor RWS verbonden aan de toepassing?",
        slug: "topicQuestion12",
      },
      13: {
        id: 13,
        question:
          "Is er voldoende investeringsbereidheid bij externe partijen?",
        slug: "topicQuestion13",
      },
      14: {
        id: 14,
        question: "Is er voldoende investeringsbereidheid bij RWS?",
        slug: "topicQuestion14",
      },
      15: {
        id: 15,
        question: "Is er een business model voor alle partijen die investeren?",
        slug: "topicQuestion15",
      },
      16: {
        id: 16,
        question:
          "Subvraag 1 Generiek: hoe hoog zijn de (geschatte) ontwikkelkosten voor RWS aan deze innovatie?",
        slug: "topicQuestion16",
      },
      17: {
        id: 17,
        question:
          "Subvraag 2 Huidige stap: is in beeld wat de ontwikkeling van de innovatie op korte termijn kost? Hoeveel?",
        slug: "topicQuestion17",
      },
      18: {
        id: 18,
        question:
          "Subvraag 3 Doorkijk naar de toekomst: Is er een doorkijk naar de toekomst en is duidelijk wat de ontwikkeling RWS kost? (ordegrootte)",
        slug: "topicQuestion18",
      },
      19: {
        id: 19,
        question:
          "Subvraag 1 Aanschaf-kosten: Zijn er meerkosten voor de aanschaf? (ordegrootte)",
        slug: "topicQuestion19",
      },
      20: {
        id: 20,
        question:
          "Subvraag 2 Operationele kosten: Zijn er operationele kosten (support, energiekosten, onderhouds- en varvangingskosten) op hoofdlijnen? (ordegrootte)",
        slug: "topicQuestion20",
      },
      21: {
        id: 21,
        question:
          "Subvraag 3 Transitiekosten: Zijn er kosten verbonden aan de overstap (bijvoorbeeld omscholing werknemers, training, afkomen van huidige producten/werkwijzen)? (ordegrootte)",
        slug: "topicQuestion21",
      },
      22: {
        id: 22,
        question:
          "Subvraag 1 Marktpartijen: Is er investeringsbereidheid van (andere) marktpartijen in de innovatie? Wie zijn deze partijen (innovator zelf, andere marktpartijen / ontwikkelaars)?",
        slug: "topicQuestion22",
      },
      23: {
        id: 23,
        question:
          "Subvraag 2 Andere afnemers: Is er voldoende (zicht op) investeringsbereidheid van (andere) afnemers in (de ontwikkeling en/of toepassing van) de innovatie? Wie zijn deze partijen (andere overheden/afnemers)?",
        slug: "topicQuestion23",
      },
      24: {
        id: 24,
        question:
          "Past de innovatie in de huidige wet- en regelgeving (extern RWS)?",
        slug: "topicQuestion24",
      },
      25: {
        id: 25,
        question:
          "Past de innovatie binnen de kaders en richtlijnen intern RWS?",
        slug: "topicQuestion25",
      },
      26: {
        id: 26,
        question: "Past de innovatie in de inkoopstrategie van RWS?",
        slug: "topicQuestion26",
      },
      27: {
        id: 27,
        question:
          "Subvraag 1 Aanbesteding: Past de wijze van inkopen/contracteren in de aanbestedingswet? Moet er iets geregeld worden in de inkoopstrategie om deze innovatie rechtmatig in te kunnen kopen? Hoeveel moeite kost het om deze aanpassingen te regelen?",
        slug: "topicQuestion27",
      },
      28: {
        id: 28,
        question:
          "Subvraag 2 Contract: Past de innovatie in de huidige manier van contracteren of moeten er in de contracten aanpassingen gedaan worden om ruimte te geven aan de innovatie of nadrukkelijk aandacht worden besteed aan het marktrijp maken en aan de toepassing van de innovatie (intellectueel eigendom, licenties, gebruikersrecht, etc)? Hoeveel moeite kost het om dit te regelen?",
        slug: "topicQuestion28",
      },
      29: {
        id: 29,
        question:
          "Is de innovatie makkelijk inpasbaar in de werkprocessen van RWS? Is de inpasbaarheid in de RWS-werkprocessen besproken met en getoetst bij mensen die in deze werkprocessen werken? Deze opmerking geldt voor alle vragen die gaan over inpasbaarheid in RWS- werkprocessen.",
        slug: "topicQuestion29",
      },
      30: {
        id: 30,
        question:
          "Subvraag 1 Planning/programmering/budgettering: Heeft de innovatie impact op deze processen? Past de innovatie in plannings-processen/programmerings-processen/budgetterings-processen? Zijn de aanpassingen die nodig zijn te regelen?",
        slug: "topicQuestion30",
      },
      31: {
        id: 31,
        question:
          "Subvraag 2 Beheer en onderhoud: Heeft de innovatie impact op beheer en onderhoud? Past de innovatie in beheers- en onderhoudsactiviteiten? Zijn de aanpassingen die nodig zijn te regelen?",
        slug: "topicQuestion31",
      },
      32: {
        id: 32,
        question:
          "Subvraag 3 ICT systemen: Heeft de innovatie impact op ICT-systemen? Past de innovatie binnen de huidige ICT-systemen van RWS? Zijn de aanpassingen te regelen?",
        slug: "topicQuestion32",
      },
      33: {
        id: 33,
        question:
          "Subvraag 4 Andere werkprocessen dan bovenstaande: Zijn er andere werkprocessen waar de innovatie impact op heeft? Zijn daarbij nog aanpassingen nodig die te regelen zijn?",
        slug: "topicQuestion33",
      },
      34: {
        id: 34,
        question: "Is de innovatie makkelijk in te passen voor de aannemer?",
        slug: "topicQuestion34",
      },
      35: {
        id: 35,
        question: "Is de veranderdruk voor het werk van RWS’ers beperkt?",
        slug: "topicQuestion35",
      },
      36: {
        id: 36,
        question:
          "Subvraag 1 Werkzekerheid: Beinvloedt de innovatie de werkzekerheid van mensen? Is de inpassing qua werkzekerheid lastig?",
        slug: "topicQuestion36",
      },
      37: {
        id: 37,
        question:
          "Subvraag 2 Aard van de werkzaamheden: Heeft de innovatie impact op de aard van de werkzaamheden van werknemers? Is de inpassing qua aard van de werkzaamheden lastig (bijvoorbeeld d.m.v. extra opleiding)?",
        slug: "topicQuestion37",
      },
      38: {
        id: 38,
        question:
          "Is de innovatie betrouwbaar qua functioneren onder alle mogelijke omstandigheden?",
        slug: "topicQuestion38",
      },
      39: {
        id: 39,
        question:
          "Brengt de innovatie risico’s voor de beschikbaarheid van netwerken of dienstverlening met zich mee?",
        slug: "topicQuestion39",
      },
      40: {
        id: 40,
        question:
          "Kan het onderhoud worden uitgevoerd onder de gestelde randvoorwaarden qua beschikbare tijd?",
        slug: "topicQuestion40",
      },
      41: {
        id: 41,
        question:
          "Is het systeem gevoelig voor verstoringen van buitenaf (zoals vandalisme of diefstal)",
        slug: "topicQuestion41",
      },
      42: {
        id: 42,
        question:
          "Voldoet de innovatie aan de geldende veiligheidseisen of kunnen er onacceptabele veiligheidsrisico’s optreden?",
        slug: "topicQuestion42",
      },
      43: {
        id: 43,
        question:
          "Heeft de innovatie een (negatieve) impact op de (gezondheid van) gebruikers, omwonenden en werknemers?",
        slug: "topicQuestion43",
      },
      44: {
        id: 44,
        question:
          "Heeft de innovatie een (negatieve) impact op natuur en leefomgeving?",
        slug: "topicQuestion44",
      },
      45: {
        id: 45,
        question:
          "Heeft de innovatie meerwaarde in de vorm van een gunstige prijs-kwaliteitverhouding?",
        slug: "topicQuestion45",
      },
      46: {
        id: 46,
        question: "Past de innovatie binnen de context van politiek en beleid?",
        slug: "topicQuestion46",
      },
    },
    topicStatus: {
      1: {
        id: 1,
        name: "Nog niet gestart",
        color: "grijs-1",
      },
      2: {
        id: 2,
        name: "Niet van toepassing",
        color: "grijs-4",
      },
      3: {
        id: 3,
        name: "Nog onbekend",
        color: "grijs-6",
      },
      4: {
        id: 4,
        name: "Lijkt niet in orde, mogelijk aandachtspunt",
        color: "donkergeel",
      },
      5: {
        id: 5,
        name: "Niet in orde, aandachtspunt",
        color: "rood",
      },
      6: {
        id: 6,
        name: "Lijkt in orde, maar weet het nog niet zeker",
        color: "groen",
      },
      7: {
        id: 7,
        name: "In orde (gevalideerd/geregeld)",
        color: "donkergroen",
      },
    },
    topicStatuses: [1, 2, 3, 4, 5, 6, 7],
  },
};

export default initialState;
