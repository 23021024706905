/*
 Action types
 */
export const TOGGLE_ALLOWCONTACT = "TOGGLE_ALLOWCONTACT";
export const RESET_STATE = "RESET_STATE";
export const SET_SECTIONQUESTION = "SET_SECTIONQUESTION";
export const SET_TOPICQUESTION = "SET_TOPICQUESTION";
export const SET_TOPICQUESTION_TITLE = "SET_TOPICQUESTION_TITLE";
export const SET_TOPICSTATUS = "SET_TOPICSTATUS";
export const UPDATE_PROJECTINFO = "UPDATE_PROJECTINFO";
export const UPDATE_APP_DATA = "UPDATE_APP_DATA";
export const APP_DATA_SAVED = "APP_DATA_SAVED";
export const SET_NEW_ASPECT = "SET_NEW_ASPECT";
export const SET_TOPIC_TITLE = "SET_TOPIC_TITLE";
export const DELETE_TOPIC = "DELETE_TOPIC";

/*
 Actions
 */
export const setProjectInfo = (payload) => ({
  type: UPDATE_PROJECTINFO,
  payload,
});
export const setSectionQuestion = (payload) => ({
  type: SET_SECTIONQUESTION,
  payload,
});
export const setTopicQuestion = (payload) => ({
  type: SET_TOPICQUESTION,
  payload,
});
export const deleteTopic = (payload) => ({ type: DELETE_TOPIC, payload });
export const setTopicQuestionTitle = (payload) => ({
  type: SET_TOPICQUESTION_TITLE,
  payload,
});
export const setTopicTitle = (payload) => ({
  type: SET_TOPIC_TITLE,
  payload,
});
export const setTopicStatus = (payload) => ({
  type: SET_TOPICSTATUS,
  payload,
});
export const setNewAspect = (payload) => ({
  type: SET_NEW_ASPECT,
  payload,
});
export const toggleAllowContact = (payload) => ({
  type: TOGGLE_ALLOWCONTACT,
  payload,
});
export const resetState = (payload) => ({
  type: RESET_STATE,
  payload,
});
export const updateAppData = (payload) => ({
  type: UPDATE_APP_DATA,
  payload,
});
export const appDataSaved = (payload) => ({
  type: APP_DATA_SAVED,
  payload,
});
