import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import FormField from "../global/FormField";
import { sections } from "./FeedbackQuestions";
import { Error, Success } from "../global/Alert";
import ScrollToTop from "../global/ScrollToTop";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Parser from "html-react-parser";
import Language from "../../helpers/language";
import apiConnector from "../../helpers/apiConnector";
import LoadingSpinner from "../global/LoadingSpinner";

const Feedback = () => {
  const [formData, setFormData] = useState({});
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formSubmitError, setFormSubmitError] = useState(false);
  const { t } = useTranslation();
  const handleSubmit = (e) => {
    e.preventDefault();
    setFormSubmitting(true);

    const data = [];

    // Merge questions and answers
    sections.forEach((section) => {
      const s = {
        name: section.name,
        sectionQuestions: [],
      };

      section.questions.forEach((question) => {
        const name = `feedbackQuestion${question.id}`;
        const answer = formData[name] ?? "";

        s.sectionQuestions.push({
          ...question,
          answer: answer,
          name: question.label,
          helpText: question.placeholder,
        });
      });

      data.push(s);
    });

    apiConnector
      .connect("post", "feedback", false, data)
      .then((result) => {
        console.log(result);
        setFormSubmitted(true);
      })
      .catch(() => {
        setFormSubmitError(true);
      })
      .finally(() => {
        setFormSubmitting(false);
      });
  };
  const handleChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const onConfirmClose = () => {
    setFormSubmitted(false);
    setFormData({});
  };

  return (
    <main>
      <ScrollToTop />
      <div className="container py-4">
        <div className="row">
          <div className="col-md-8">
            <h1>{Language.getTranslation("heading", "feedback")}</h1>
            <div className="alert alert-info">
              {Parser(t("feedback.introText"))}
            </div>

            <form onSubmit={handleSubmit} autoComplete="off">
              <Accordion defaultActiveKey={0}>
                {sections.map((section, index) => {
                  return (
                    <Card key={`feedbackSection${index}`}>
                      <Card.Header>
                        <Accordion.Toggle
                          as={Button}
                          variant="link"
                          eventKey={`feedbackSection${index}`}
                        >
                          <Trans i18nKey="Stap">Stap</Trans> {index + 1}/
                          {sections.length}: <Trans>{section.name}</Trans>
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey={`feedbackSection${index}`}>
                        <Card.Body>
                          {section.questions.map((question) => {
                            const name = `feedbackQuestion${question.id}`;

                            return (
                              <div className="mb-4" key={name}>
                                <FormField
                                  {...question}
                                  label={t(question.label)}
                                  placeholder={
                                    question.hasOwnProperty("placeholder")
                                      ? t(question.placeholder)
                                      : ""
                                  }
                                  options={
                                    question.hasOwnProperty("options")
                                      ? {
                                          minLabel: t(
                                            question.options.minLabel
                                          ),
                                          maxLabel: t(
                                            question.options.maxLabel
                                          ),
                                        }
                                      : {}
                                  }
                                  handleChange={handleChange}
                                  name={name}
                                  value={formData[name] ?? ""}
                                />
                              </div>
                            );
                          })}
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  );
                })}
              </Accordion>

              <fieldset className="pt-4 mb-4 d-flex align-items-center">
                <button type="submit" className="btn btn-next btn-warning mr-3">
                  {t("feedback.submitBtn")}
                </button>

                {formSubmitting ? <LoadingSpinner small inline /> : <></>}
              </fieldset>
            </form>
          </div>
        </div>
      </div>
      {formSubmitted && <Success onConfirm={onConfirmClose} />}
      {formSubmitError && (
        <Error
          onConfirm={() => {
            setFormSubmitError(false);
          }}
        />
      )}
    </main>
  );
};

export default Feedback;
