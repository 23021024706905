import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import rws_2020_en from "./locales/rws-2020-en/translation.json";
import rws_2020_nl from "./locales/rws-2020-nl/translation.json";
import rws_2020_de from "./locales/rws-2020-de/translation.json";
import rws_2020_fr from "./locales/rws-2020-fr/translation.json";

// the translations
const resources = (appId, lang) => {
  switch (appId) {
    case "rws-2020-en":
      return {
        [lang]: {
          translation: rws_2020_en,
        },
      };
    case "rws-2020-de":
      return {
        [lang]: {
          translation: rws_2020_de,
        },
      };
    case "rws-2020-fr":
      return {
        [lang]: {
          translation: rws_2020_fr,
        },
      };
    case "rws-2020-nl":
    default:
      return {
        [lang]: {
          translation: rws_2020_nl,
        },
      };
  }
};

const setLanguage = (appId, lng) => {
  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources: resources(appId, lng),
      lng: lng,
      fallbackLng: "nl",
      // keySeparator: false, // we do not use keys in form messages.welcome
      interpolation: {
        escapeValue: false, // react already safes from xss
      },
    });
};

export default setLanguage;
