import React, { useState } from "react";
import ScrollToTop from "../global/ScrollToTop";
import FormField from "../global/FormField";
import { Trans, useTranslation } from "react-i18next";
import Language from "../../helpers/language";
import apiConnector from "../../helpers/apiConnector";
import isEmail from "sane-email-validation";
import { Error, Success } from "../global/Alert";
import LoadingSpinner from "../global/LoadingSpinner";

const Contact = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({ email: "", message: "" });
  const [usedHoneyPot, setUsedHoneyPot] = useState(false);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formSubmitError, setFormSubmitError] = useState(false);

  const isValidFormData = () => {
    if (!formData.email || !isEmail(formData.email) || !formData.message) {
      return false;
    }

    return true;
  };

  const sendContactInfo = () => {
    if (usedHoneyPot || !isValidFormData()) {
      return;
    }

    setFormSubmitting(true);

    apiConnector
      .connect("post", "contact", false, formData)
      .then((result) => {
        console.log(result);
        setFormSubmitted(true);
      })
      .catch(() => {
        setFormSubmitError(true);
      })
      .finally(() => {
        setFormSubmitting(false);
      });
  };

  const handleChange = (name, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleHoneyPot = () => {
    setUsedHoneyPot(true);
  };

  const onConfirmClose = () => {
    setFormSubmitted(false);
    setFormData({ email: "", message: "" });
  };

  return (
    <main>
      <ScrollToTop />
      <div className="container py-4">
        <div className="row">
          <div className="col-md-8">
            <h1>
              <Trans i18nKey="appContact.Title">Contact</Trans>
            </h1>
            <FormField
              type="text"
              label={"Email*"}
              name="email"
              id="email"
              handleChange={handleChange}
            />
            <FormField
              id="message"
              type="textareaSmall"
              name="message"
              label={
                t(Language.getTranslation("messageLabel", "contact")) + "*"
              }
              handleChange={handleChange}
            />

            <label for="honeypot">
              Honeypot
              <input
                tabIndex={-1}
                onChange={handleHoneyPot}
                id="honeypot"
                name="honeypot"
                type="text"
              />
            </label>

            <div class="d-flex mt-4 align-items-center gap-3">
              <button
                type="button"
                className="btn btn-primary mr-3"
                disabled={
                  !formData.email ||
                  !isEmail(formData.email) ||
                  !formData.message
                }
                onClick={sendContactInfo}
              >
                {t("feedback.submitBtn")}
              </button>

              {formSubmitting ? <LoadingSpinner small inline /> : <></>}
            </div>
          </div>
        </div>
      </div>

      {formSubmitted && <Success onConfirm={onConfirmClose} />}
      {formSubmitError && (
        <Error
          onConfirm={() => {
            setFormSubmitError(false);
          }}
        />
      )}
    </main>
  );
};

export default Contact;
