import "core-js/stable";
import "regenerator-runtime/runtime";
import React from 'react';
import ReactDOM from 'react-dom';
import PiwikPro from '@piwikpro/react-piwik-pro';
import App from './App';
import env from "./config/env.json";
import * as serviceWorker from './serviceWorker';

PiwikPro.initialize(env.piwikProContainerId, env.piwikProContainerUrl);

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
