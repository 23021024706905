import React from "react";
import Language from "../../helpers/language/";
import { Trans } from "react-i18next";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-6">
            <h2>
              <Trans i18nKey="appFooter.Subtitle1">
                {Language.getTranslation("aboutTheOnlineSrlTool")}
              </Trans>
            </h2>
            <p className="lead">
              <Trans i18nKey="appFooter.Paragraph1">
                {Language.getTranslation("aboutTheOnlineSrlToolDescription")}
              </Trans>
            </p>
            <h2>
              <Trans i18nKey="appFooter.Subtitle2">
                {Language.getTranslation("disclaimerTitle")}
              </Trans>
            </h2>
            <p className="lead">
              <Trans i18nKey="appFooter.Paragraph2">
                {Language.getTranslation("disclaimerText")}
              </Trans>
            </p>
          </div>
          <div className="col-3">
            <h2>{Language.getTranslation("service")}</h2>
            <ul className="list-unstyled">
              <li>
                <a
                  href="https://www.rijkswaterstaat.nl/privacy/index.aspx"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {Language.getTranslation("privacy")}
                </a>
              </li>
              <li>
                <a
                  href="https://www.rijkswaterstaat.nl/cookies.aspx"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {Language.getTranslation("cookies")}
                </a>
              </li>
              <li>
                <a
                  href="https://www.rijkswaterstaat.nl/toegankelijkheid.aspx"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Trans i18nKey="appFooter.Accessibility">
                    {Language.getTranslation("accessibility")}
                  </Trans>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
