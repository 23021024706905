import React, { useEffect } from "react";
import { Route, Switch, useLocation, useRouteMatch } from "react-router-dom";
import { PageViews } from "@piwikpro/react-piwik-pro";

import Home from "../components/static/Home";
import Srl from "../containers/srl/";
import Feedback from "../components/static/Feedback";
import Help from "../components/static/Help";
import Privacy from "../components/static/Privacy";
import Accessibility from "../components/static/Accessibility";
import Contact from "../components/static/Contact";
import Export from "./export";

function Routes() {
  let { path, url } = useRouteMatch();
  path = path === "/" ? "" : path;

  const location = useLocation();
  useEffect(() => {
    PageViews.trackPageView(location.pathname);
  }, [location]);

  return (
    <Switch>
      <Route path={url} exact strict component={Home} />
      <Route path={`${path}/srl`} component={Srl} />
      <Route path={`${path}/export`} component={Export} />
      <Route path={`${path}/feedback`} exact strict component={Feedback} />
      <Route path={`${path}/help`} exact strict component={Help} />
      <Route path={`${path}/contact`} exact strict component={Contact} />
      <Route path={`${path}/privacy`} exact strict component={Privacy} />
      <Route
        path={`${path}/toegankelijkheid`}
        exact
        strict
        component={Accessibility}
      />
    </Switch>
  );
}

export default Routes;
