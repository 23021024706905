const helpers = {
  getItemFromArrayByValue(arr, attr, value) {
    for (let i = 0, iLen = arr.length; i < iLen; i++) {
      if (arr[i][attr] === value) return arr[i];
    }
  },
  getItemFromObject(obj, key, needle) {
    const itemArray = Object.values(obj);
    let itemFound = { noItemFound: true };
    itemArray.forEach((i) => {
      if (i.hasOwnProperty(key) && i.urlSlug === needle) {
        itemFound = i;
        return i;
      }
    });
    return itemFound;
  },
  getParameterFromUrl(param) {
    let search = "/" + param + "/";
    let posStart = window.location.pathname.indexOf(search) + search.length; // locate the parameter in the url
    let posEnd = window.location.pathname.indexOf("/", posStart);
    if (posEnd === -1) {
      return window.location.pathname.slice(posStart);
    } else {
      return window.location.pathname.slice(posStart, posEnd);
    }
  },
  handleKeyPress(event, key, callback) {
    if (event.key === key) {
      callback();
    }
  },
  handleKeyPressRef(event, key, ref) {
    if (event.key === key) {
      ref.click();
    }
  },
  hasNumber(myString) {
    return /\d/.test(myString);
  },
  hasAlphabetical(myString) {
    return /[a-z]/i.test(myString);
  },
  isInt(value) {
    let x;
    return isNaN(value) ? !1 : ((x = parseFloat(value)), (0 | x) === x);
  },
};

export default helpers;
