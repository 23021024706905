import Language from "../../../helpers/language";
import {
  DELETE_TOPIC,
  RESET_STATE,
  SET_NEW_ASPECT,
  SET_TOPICQUESTION_TITLE,
  SET_TOPIC_TITLE,
} from "../actions";

const structure = (state = {}, { type, payload }) => {
  switch (type) {
    case SET_NEW_ASPECT:
      const newTopicQuestionKey = Object.keys(state.topicQuestion).length + 1;
      return {
        ...state,
        topicQuestion: {
          ...state.topicQuestion,
          [newTopicQuestionKey]: {
            id: newTopicQuestionKey,
            question: null,
            slug: `topicQuestion${newTopicQuestionKey}`,
          },
        },
        topic: {
          ...state.topic,
          [payload.newTopicKey]: {
            id: payload.newTopicKey,
            name: Language.getTranslation("newSubject"),
            description: null,
            topicQuestions: [newTopicQuestionKey],
            urlSlug: `newTopic${payload.newTopicKey}`,
            extraTopic: true,
          },
        },
        theme: {
          ...state.theme,
          [payload.activeThemeId]: {
            ...state.theme[payload.activeThemeId],
            topics: [
              ...state.theme[payload.activeThemeId].topics,
              payload.newTopicKey,
            ],
            hasExtraTopic: true,
          },
        },
      };
    case SET_TOPIC_TITLE:
      return {
        ...state,
        topic: {
          ...state.topic,
          [payload.topicId]: {
            ...state.topic[payload.topicId],
            name: payload.title,
          },
        },
      };
    case SET_TOPICQUESTION_TITLE:
      return {
        ...state,
        topicQuestion: {
          ...state.topicQuestion,
          [payload.topicQuestion.id]: {
            ...state.topicQuestion[payload.topicQuestion.id],
            question: payload.questionTitleState,
          },
        },
      };
    case DELETE_TOPIC:
      const obj = {
        ...state,
        theme: {
          ...state.theme,
          [payload.activeTheme.id]: {
            ...state.theme[payload.activeTheme.id],
            topics: state.theme[payload.activeTheme.id].topics.filter(
              (topicId) => topicId !== payload.topic.id
            ),
            hasExtraTopic: false,
          },
        },
      };
      if (obj) {
        delete obj.topic[payload.topic.id];
      }
      return {
        ...obj,
      };
    case RESET_STATE:
      return payload.structure;
    default:
      return state;
  }
};

export default structure;
