import { applyMiddleware, createStore } from "redux";
import rootReducer from "../containers/srl/reducers";
import getInitialState from "../containers/srl/reducers/initialState";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import moment from "moment";
import slugify from "slugify";
import { APP_DATA_SAVED, updateAppData } from "../containers/srl/actions";
import md5 from "md5";

const stateKey = "srl_data";
const fileExtension = "srl";
const fileDateFormat = "Y-MM-DD-HH-mm-ss";

const saveToSessionStorage = (store) => (next) => (action) => {
  next(action);

  const stateToSave = store.getState();
  localStorage.setItem(stateKey, JSON.stringify({ ...stateToSave }));
};

const dataHasChanged = (store) => (next) => (action) => {
  const currentState = store.getState();
  const oldHash = currentState.app.hash;

  // Let the reducers do their thing
  next(action);

  const newState = store.getState();
  const newHash = generateStateHash(newState);

  if (action.type === APP_DATA_SAVED) {
    // Save button is pressed
    store.dispatch(
      updateAppData({
        changesSaved: true,
        hash: newHash,
      })
    );
  } else {
    // Any other action
    const changesSaved = newState.app.changesSaved;

    if (oldHash === newHash && changesSaved === false) {
      // With the given edit the data is the same again
      store.dispatch(
        updateAppData({
          changesSaved: true,
        })
      );
    } else if (oldHash !== newHash && changesSaved !== false) {
      // State contains data not saved yet
      store.dispatch(
        updateAppData({
          changesSaved: false,
        })
      );
    }
  }
};

const generateStateHash = (state) => {
  return md5(JSON.stringify(state.projectData));
};

const create = (appId, lang) => {
  const initialState = getStateData(appId, lang);

  return createStore(
    rootReducer,
    initialState,
    applyMiddleware(saveToSessionStorage, dataHasChanged)
  );
};

const getStateData = (appId, lang) => {
  const state = tryParseJSON(localStorage.getItem(stateKey));
  const initialState = getInitialState(appId, lang);

  if (state === false) {
    return initialState;
  }

  // Apply structure again
  return {
    ...state,
  };
};

const tryParseJSON = (jsonString) => {
  try {
    const o = JSON.parse(jsonString);

    // Handle non-exception-throwing cases:
    // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
    // but... JSON.parse(null) returns null, and typeof null === "object",
    // so we must check for that, too. Thankfully, null is falsey, so this suffices:
    if (o && typeof o === "object") {
      return o;
    }
  } catch (e) {}

  return false;
};

const exportToJSON = async () => {
  const data = getStateData();

  createFile(data).then((content) => {
    saveAs(content, createFileName(data, fileExtension));
  });
};

const createFile = (data) => {
  const zip = new JSZip();
  const path = createFileName(data);
  zip.file(path, JSON.stringify(data));

  return zip.generateAsync({ type: "blob" });
};

const createFileName = (data, extension = "json") => {
  let projectNameRaw = data.projectData.projectInfo.projectName;

  if (projectNameRaw === "") {
    projectNameRaw = "Naamloos";
  }

  const projectName = slugify(projectNameRaw);
  const currentDate = moment().format(fileDateFormat);

  return `${projectName}-${currentDate}.${extension}`;
};

export { create as default, exportToJSON, generateStateHash, tryParseJSON };
