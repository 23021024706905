import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { mapSectionQuestionDispatch, mapSectionQuestionState } from "./mapping";
import FormField from "../../../components/global/FormField";
import { useTranslation } from "react-i18next";

const SectionQuestion = ({ answer, question, setSectionQuestion }) => {
  const { t } = useTranslation();

  const handleChange = (newValue, event) =>
    setSectionQuestion({ [question.id]: newValue });
  return (
    <FormField
      handleChange={handleChange}
      helpText={
        question.helpText
          ? t(
              `sectionQuestion.sectionQuestion${question.id}.helptext`,
              question.helpText
            )
          : ""
      }
      label={t(
        `sectionQuestion.sectionQuestion${question.id}.title`,
        question.name
      )}
      name={"section1" + question.slug}
      type="wysiwyg"
      useSmallWysiwyg={question.useSmallWysiwyg}
      value={answer}
    />
  );
};

SectionQuestion.propTypes = {
  question: PropTypes.object.isRequired,
};

export default connect(
  mapSectionQuestionState,
  mapSectionQuestionDispatch
)(SectionQuestion);
