export const sections = [
    {
        'name': 'Wat betekent de tool voor u?',
        'questions': [
            {
                'id': 1,
                'label': 'In hoeverre heeft het werken met deze tool u en uw innovatie geholpen?',
                'type': 'textareaSmall',
                'rows': 2,
            },
            {
                'id': 2,
                'label': 'Heeft de tool bij u tot nieuwe inzichten en/of aandachtspunten geleid?',
                'type': 'range',
                'options': {
                    'minLabel': 'zeer weinig geholpen',
                    'maxLabel': 'zeer veel geholpen',
                },
            },
            {
                'id': 16,
                'placeholder': 'Geef (eventueel) uw toelichting',
                'rows': 2,
                'type': 'textareaSmall',
            },
            {
                'id': 3,
                'label': 'Heeft het u tot nieuwe acties aangezet die u nog niet eerder op uw netvlies had en voelt u ook de urgentie om dat daadwerkelijk te gaan doen?',
                'type': 'textareaSmall',
                'rows': 2,
            },
        ],
    },
    {
        'name': 'Over de inhoud en vorm van de tool',
        'questions': [
            {
                'id': 4,
                'label': 'Vond u de vragenlijst compleet of miste u nog vragen?',
                'type': 'range',
                'options': {
                    'minLabel': 'incompleet',
                    'maxLabel': 'zeer compleet',
                },
            },
            {
                'id': 5,
                'placeholder': 'Geef (eventueel) uw toelichting',
                'rows': 2,
                'type': 'textareaSmall',
            },
            {
                'id': 6,
                'label': 'Hoe duidelijk zijn de thema’s en onderliggende vragen omschreven?',
                'type': 'range',
                'options': {
                    'minLabel': 'zeer onduidelijk',
                    'maxLabel': 'zeer duidelijk',
                },
            },
            {
                'id': 7,
                'label': 'Hoe visueel aantrekkelijk is de tool?',
                'type': 'range',
                'options': {
                    'minLabel': 'zeer onaantrekkelijk',
                    'maxLabel': 'zeer aantrekkelijk',
                },
            },
        ],
    },
    {
        'name': 'Over het gebruik van de tool',
        'questions': [
            {
                'id': 8,
                'label': 'Was u, over het algemeen gezien, tevreden of ontevreden over het werken met de online tool?',
                'type': 'range',
                'options': {
                    'minLabel': 'zeer ontevreden',
                    'maxLabel': 'zeer tevreden',
                },
            },
            {
                'id': 9,
                'placeholder': 'Geef (eventueel) uw toelichting',
                'rows': 2,
                'type': 'textareaSmall',
            },
            {
                'id': 10,
                'label': 'Hoe gemakkelijk is het om te navigeren door de tool?',
                'type': 'range',
                'options': {
                    'minLabel': 'zeer moeilijk',
                    'maxLabel': 'zeer makkelijk',
                },
            },
            {
                'id': 11,
                'label': 'Kostte het u meer of minder tijd dan u had verwacht om deze tool te gebruiken?',
                'type': 'range',
                'options': {
                    'minLabel': 'veel meer tijd dan verwacht',
                    'maxLabel': 'veel minder tijd dan verwacht',
                },
            },
            {
                'id': 12,
                'label': 'Welke verbeterpunten heeft u voor de tool?',
                'placeholder': 'Geef (eventueel) uw toelichting',
                'type': 'textareaSmall',
                'rows': 2,
            },
        ],
    },
    {
        'name': 'Hoe verder?',
        'questions': [
            {
                'id': 13,
                'label': 'Hoe waarschijnlijk is het dat u deze tool nog een keer zou gebruiken?',
                'type': 'range',
                'options': {
                    'minLabel': 'zeer onwaarschijnlijk',
                    'maxLabel': 'zeer waarschijnlijk',
                },
            },
            {
                'id': 14,
                'label': 'Hoe waarschijnlijk is het dat u deze tool aanbeveelt aan anderen?',
                'type': 'range',
                'options': {
                    'minLabel': 'zeer onwaarschijnlijk',
                    'maxLabel': 'zeer waarschijnlijk',
                },
            },
            {
                'id': 15,
                'label': 'Heeft u nog andere, generieke opmerkingen?',
                'placeholder': 'Geef (eventueel) uw toelichting',
                'type': 'textareaSmall',
                'rows': 2,
            },
        ],
    },
];
