import React from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import Chart, {hideTooltip, topicTooltip} from "../../../components/global/Chart";
import {mapThemeChartState} from "../../../containers/srl/_section2/mapping";
import {generatePath, useHistory, useRouteMatch} from "react-router-dom";
import {StatusHelper} from "../../../helpers/statusHelper";
import Colors from "../../../css/export-theme-for-react.scss";
import {useTranslation} from "react-i18next";

const defaultProps = {
    activeTopic: {id: null},
    theme: {
        topics: [],
    },
    topics: {},
    topicStatuses: {},
};

const ThemeChart = ({theme = defaultProps.theme, topics = defaultProps.topics, topicStatuses = defaultProps.topicStatuses, chartRef = undefined}) => {
    let {path} = useRouteMatch();
    let dataset = [];
    let colors = [];
    let hoverColors = [];
    let borderColors = [];
    const history = useHistory();
    const {t} = useTranslation();

    const handleClick = (index, dataset) => {
        hideTooltip();

        const data = dataset[index];
        history.push(generatePath(path, {step: 2, activeTheme: data.theme.urlSlug}) + '/' + data.topic.urlSlug);
    }

    theme.topics.forEach(topicId => {
        if (topics.hasOwnProperty(topicId)) {
            const statusColorHex = StatusHelper.getChartColorHex(topicStatuses, topicId);
            const statusHoverColorHex = StatusHelper.getChartHoverColorHex(topicStatuses, topicId);

            dataset.push({
                label: topics[topicId].name,
                description: topics[topicId].description,
                isTopic: true,
                topic: topics[topicId],
                topics: Object.values(topics),
                theme: theme,
                value: Math.round((100 / theme.topics.length) * 100) / 100,
                statusColorHex: statusColorHex,
            });

            // pick the status color from the array
            colors.push(statusColorHex);
            hoverColors.push(statusHoverColorHex);
            borderColors.push(Colors['white']);
        }
    });

    if (dataset.length === 0) {
        return null;
    }

    const data = {
        labels: dataset.map(d => d.label),
        datasets: [{
            backgroundColor: colors,
            borderColor: Colors['white'],
            hoverBorderColor: Colors['white'],
            borderAlign: 'inner',
            data: dataset.map(d => d.value),
            hoverBackgroundColor: hoverColors,
            label: theme.name,
        }],
    };
    const options = {
        tooltips: {
            enabled: false,
            custom: function (tooltipModel) {
                topicTooltip(tooltipModel, this._chart, dataset, t)
            },
        },
    };

    return <Chart data={data} options={options} handleClick={(index) => handleClick(index, dataset)} chartRef={chartRef} />
}

export default connect(mapThemeChartState)(ThemeChart);

ThemeChart.propTypes = {
    activeTopic: PropTypes.object,
    theme: PropTypes.object.isRequired,
    topics: PropTypes.object.isRequired,
    topicStatuses: PropTypes.object.isRequired,
};
