import React from "react";
import { Link, useRouteMatch } from "react-router-dom";
import ScrollToTop from "../global/ScrollToTop";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { Trans } from "react-i18next";
import EUImage from "../../assets/images/eu-logo.png";
import CoverImage from "../../assets/images/background-srl.png";

const Home = () => {
  let { path } = useRouteMatch();
  path = path === "/" ? "" : path;

  return (
    <main>
      <ScrollToTop />
      <div className="site-cover">
        <div className="container site-cover-wrapper py-3">
          <div className="site-cover-image-container">
            <img src={CoverImage} className="site-cover-image" alt="" />
          </div>
          <div className="site-cover-text">
            <h1>
              <Trans i18nKey={"appHome.TopTitle"}>
                Versnel je innovatie in 5 stappen
              </Trans>
            </h1>
            <h2>
              <Trans i18nKey="appHome.Title">
                Stakeholder Readiness Level (SRL)-tool
              </Trans>
            </h2>
            <h3>
              <Trans i18nKey="appHome.Subtitle">
                Vroegtijdige aandacht voor implementatie van innovatie
              </Trans>
            </h3>

            <p className="lead">
              <Trans i18nKey="appHome.Introtext">
                Innovaties in de dagelijkse praktijk brengen is een grote
                uitdaging. Een hulpmiddel hierbij is de Stakeholder Readiness
                Level-tool. Deze SRL-tool laat zien in hoeverre de organisatie
                en omgeving klaar zijn voor de toepassing van een bepaalde
                innovatie. De tool helpt om dit inzichtelijk te maken en toont
                wat er nog geregeld moet worden. Denk aan: waarde, draagvlak,
                kosten, inpasbaarheid en risico’s. Het vroegtijdig aandacht
                schenken aan de SRL-aspecten kan de toepassing van een innovatie
                versnellen. Het instrument is ontwikkeld door Rijkswaterstaat,
                maar ook andere organisaties die met innovaties aan de slag
                willen kunnen het gebruiken.
              </Trans>
            </p>

            <p className="lead">
              <Trans i18nKey="appHome.Disclaimer">
                Daar waar Rijkswaterstaat (RWS) staat kan je ook de naam van je
                eigen organisatie lezen.
              </Trans>
            </p>
          </div>
        </div>
      </div>
      <div className="container py-4">
        <div className="row">
          <div className="col-9">
            <Accordion>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="0">
                    <Trans i18nKey="appHome.Accordion1.Title">
                      Componenten SRL
                    </Trans>
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <p>
                      <Trans i18nKey="appHome.Accordion1.Intro">
                        De SRL bestaat uit een vragenlijst die verschillende
                        aspecten omvat en wordt daarom opgebouwd uit meerdere
                        componenten:
                      </Trans>
                    </p>

                    <ol>
                      <li>
                        <Trans i18nKey="appHome.Accordion1.Text1">
                          Waarde: de meerwaarde van de innovatie voor de
                          eindgebruiker(s).
                        </Trans>
                      </li>
                      <li>
                        <Trans i18nKey="appHome.Accordion1.Text2">
                          Draagvlak: het enthousiasme binnen de organisatie,
                          maar ook de hindermacht.
                        </Trans>
                      </li>
                      <li>
                        <Trans i18nKey="appHome.Accordion1.Text3">
                          Kosten: zowel voor de ontwikkeling als de toepassing.
                        </Trans>
                      </li>
                      <li>
                        <Trans i18nKey="appHome.Accordion1.Text4">
                          Inpasbaarheid: inpasbaarheid in bestaande processen en
                          de veranderdruk die een innovatie met zich meebrengt.
                        </Trans>
                      </li>
                      <li>
                        <Trans i18nKey="appHome.Accordion1.Text5">
                          Risico's: een snelle check of de risico's op de
                          verschillende fronten bekend en beheersbaar zijn.
                        </Trans>
                      </li>
                    </ol>

                    <p>
                      <Trans i18nKey="appHome.Accordion1.TextOutro">
                        De volgorde van de componenten is van belang. Als de
                        componenten waarde en draagvlak (nog) niet op orde zijn,
                        is het veel moeilijker de andere componenten geregeld te
                        krijgen. Het is echter wel goed om daar globaal inzicht
                        in te verkrijgen: als een innovatie een beperkte waarde
                        heeft, maar lastig inpasbaar is, kan dat bijvoorbeeld
                        een reden zijn er toch niet mee verder te gaan omdat de
                        waarde niet opweegt tegen de moeite die het kost om de
                        innovatie in te passen.
                      </Trans>
                    </p>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="1">
                    <Trans i18nKey="appHome.Accordion2.Title">
                      Wat levert SRL uiteindelijk op?
                    </Trans>
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                  <Card.Body>
                    <p>
                      <Trans i18nKey="appHome.Accordion2.Intro">
                        Aandacht voor SRL in een vroegtijdig stadium van de
                        ontwikkeling van een innovatie helpt bij:
                      </Trans>
                    </p>

                    <ul>
                      <li>
                        <Trans i18nKey="appHome.Accordion2.Text1">
                          Het vergroten van de kans van slagen: u verkleint de
                          kans dat een proces richting een geslaagde innovatie
                          stagneert.
                        </Trans>
                      </li>
                      <li>
                        <Trans i18nKey="appHome.Accordion2.Text2">
                          Het efficiënt omgaan met middelen: u bent beter in
                          staat gefundeerde keuzes te maken over het wel of niet
                          inzetten van een innovatie en over het doen van
                          investeringen.
                        </Trans>
                      </li>
                      <li>
                        <Trans i18nKey="appHome.Accordion2.Text3">
                          Het versnellen van het innovatieproces: met de SRL
                          kunt u vroegtijdig signaleren wat er allemaal moet
                          gebeuren en daarop anticiperen door acties op
                          technisch en organisatorisch vlak parallel te laten
                          lopen.
                        </Trans>
                      </li>
                    </ul>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="2">
                    <Trans i18nKey="appHome.Accordion3.Title">
                      Door wie in te vullen?
                    </Trans>
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                  <Card.Body>
                    <p>
                      <Trans i18nKey="appHome.Accordion3.Intro">
                        De SRL-vragenlijst kan individueel worden ingevuld, maar
                        wordt idealiter door het team dat betrokken is bij de
                        innovatie samen ingevuld. Denk hierbij bijv. aan de
                        innovatie-aanbieder, de projectleider vanuit
                        Rijkswaterstaat. Omdat de SRL naar verschillende
                        aspecten kijkt, kan het team samen meer vragen
                        beantwoorden.
                      </Trans>
                    </p>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="3">
                    <Trans i18nKey="appHome.Accordion4.Title">
                      Gebruik SRL
                    </Trans>
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                  <Card.Body>
                    <p>
                      <Trans i18nKey="appHome.Accordion4.Intro">
                        De SRL kunt u gebruiken in verschillende fases van het
                        innovatieproces:
                      </Trans>
                    </p>

                    <ol>
                      <li>
                        <Trans i18nKey="appHome.Accordion4.Text1">
                          Foto: Een eerste globale scan van wat de status van de
                          innovatie nu is. Handig om te bepalen of een innovatie
                          voldoende meerwaarde heeft en kans maakt om
                          geïmplementeerd te worden.
                        </Trans>
                      </li>
                      <li>
                        <Trans i18nKey="appHome.Accordion4.Text2">
                          Actieplan: De SRL helpt bij het maken van een
                          actieplan: De SRL geeft aan waar extra aandacht nodig
                          is en acties benodigd zijn. Vervolgens is het zaak met
                          elkaar te kijken waarop nog welke stappen nodig zijn
                          en die in de tijd plaatsen.
                        </Trans>
                      </li>
                      <li>
                        <Trans i18nKey="appHome.Accordion4.Text3">
                          Monitoring: Het inrichten van een dynamisch
                          monitoringsproces: De SRL levert een structuur waarmee
                          het mogelijk is periodiek te monitoren om de voortgang
                          van een innovatie op diverse fronten te meten.
                        </Trans>
                      </li>
                      <li>
                        <Trans i18nKey="appHome.Accordion4.Text4">
                          Portfoliomanagement: de SRL kan ondersteunen bij
                          portfoliomanagement voor een pakket aan innovaties.
                          (Innovatie)budgetten zijn gelimiteerd en SRL helpt bij
                          het maken van keuzes bijvoorbeeld bij het prioriteren
                          van middelen voor de inzet van pilots: pilots zijn
                          vaak kostbaar en met de SRL kan men in beeld brengen
                          welke innovaties echt al zo ver zijn dat het
                          gerechtvaardigd is grote investeringen te doen.
                        </Trans>
                      </li>
                    </ol>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="4">
                    <Trans i18nKey="appHome.Accordion5.Title">Resultaat</Trans>
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="4">
                  <Card.Body>
                    <p>
                      <Trans i18nKey="appHome.Accordion5.Intro">
                        Het resultaat van de SRL-tool is een overzicht op de 5
                        verschillende componenten, met in kleur aangegeven of
                        ergens een knelpunt wordt verwacht. Het geeft een
                        indicatie van hoeveel en welke aspecten een mogelijk
                        knelpunt vormen.
                      </Trans>
                    </p>
                    <p>
                      <Trans i18nKey="appHome.Accordion5.Paragraph1">
                        Naar aanleiding van dit overzicht gaat het projectteam
                        in gesprek over de waarschijnlijkheid, importantie en
                        urgentie van de mogelijke knelpunten. Vervolgens kunnen
                        er afspraken komen over de te nemen acties om deze
                        knelpunten verder te onderzoeken en/of op te lossen.
                        Denk hierbij aan het beantwoorden van de vragen: Is het
                        haalbaar om dit knelpunt weg te nemen? Hoe makkelijk of
                        moeilijk is het? Wat en wie hebben we ervoor nodig?
                      </Trans>
                    </p>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="5">
                    <Trans i18nKey="appHome.Accordion6.Title">
                      Aan de slag met SRL
                    </Trans>
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="5">
                  <Card.Body>
                    <p>
                      <Trans i18nKey="appHome.Accordion6.Intro">
                        Voor elk van de componenten is een set vragen opgesteld
                        die ingevuld moet worden. Zo hangen onder de component
                        ‘inpasbaarheid’ onder meer vragen over de inpasbaarheid
                        in de huidige wet- en regelgeving, inpasbaarheid in
                        contracten, inpasbaarheid in werkprocessen. Door het
                        beantwoorden van een aantal checkvragen, zal de tool
                        aangeven wat de stand van zaken is op de verschillende
                        SRL-componenten. Zo wordt snel inzichtelijk waar nog
                        (extra) aandacht nodig is.
                      </Trans>
                    </p>
                    <p>
                      <Trans i18nKey="appHome.Accordion6.Paragraph1">
                        De resultaten worden samengevat in een dashboard. Hierin
                        wordt met bolletjes met gekleurde taartpunten aangegeven
                        hoe de innovatie scoort op de verschillende onderdelen.
                        Ook is het mogelijk alle resultaten in een
                        overzichtsplaatje met een groot boldiagram te krijgen.
                        De kleuren geven een signaal wat er nog moet gebeuren.
                        Er komt bij de SRL dus geen eenduidig cijfer uit zoals
                        bij de TRL. Wel kan er aan de hand van de kleuren een
                        kwalitatieve SRL-bepaling plaatsvinden. Hoe meer rood,
                        hoe meer er nog moet gebeuren. Hoe meer groen, des te
                        meer bevindt de innovatie zich in het eindstadium en is
                        de organisatie klaar om de innovatie toe te passen.
                      </Trans>
                    </p>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="6">
                    <Trans i18nKey="appHome.Accordion7.Title">TRL en SRL</Trans>
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="6">
                  <Card.Body>
                    <p>
                      <Trans i18nKey="appHome.Accordion7.Intro">
                        Hoe komt u tot een goed proces voor ontwikkeling van een
                        innovatie, zodat deze ook daadwerkelijk tot
                        implementatie komt? Een belangrijke vraag daarbij is
                        hoever de innovatie is qua technische ontwikkeling en
                        wat er nog moet gebeuren om de innovatie klaar voor
                        toepassing te maken. Dit wordt Technology Readiness
                        Level (TRL) genoemd. De TRL is al lang geleden door NASA
                        ontwikkeld en wordt steeds meer toegepast, ook
                        bijvoorbeeld door de EU. Als de TRL 9 is, is de
                        innovatie klaar voor toepassing. Binnen Rijkswaterstaat
                        pleiten we ervoor, dat net als voor de TRL, er ook al
                        vroeg aandacht is voor de SRL.
                      </Trans>
                    </p>
                    <p>
                      <Trans i18nKey="appHome.Accordion7.Paragraph1">
                        Hoever de innovatie technisch ontwikkeld is en wat er
                        nog moet gebeuren om de innovatie te kunnen toepassen,
                        wordt weergegeven met de Technology Readiness Level
                        (TRL).
                      </Trans>
                    </p>
                    <p>
                      <Trans i18nKey="appHome.Accordion7.Paragraph2">
                        Dit zorgt voor efficiëntie en vergroot de kans van
                        slagen. Aandacht voor de SRL is er in de vorm van een
                        vragenlijst die gaat over draagvlak, de waarde voor
                        eindgebruikers, kosten, inpasbaarheid en risico’s.
                      </Trans>
                    </p>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
            <div className="mt-4 d-flex align-items-center">
              <img
                className="mr-2"
                src={EUImage}
                style={{ width: 64 }}
                alt={"EU flag"}
              />
              <span>
                <Trans i18nKey="home.translationsEU">
                  Vertalingen mogelijk gemaakt met EU budget.
                </Trans>
              </span>
            </div>
          </div>
          <div className="col-3">
            <h3>
              <Trans i18nKey="appHome.Sidebar.Title1">Aan de slag</Trans>
            </h3>
            <Link
              to={`${path}/srl/1`}
              className="btn btn-warning btn-block mb-4"
            >
              <Trans i18nKey="appHome.Sidebar.Button1">
                Maak nieuw project
              </Trans>
            </Link>

            <h3>
              <Trans i18nKey="appHome.Sidebar.Title2">Hulp nodig?</Trans>
            </h3>
            <Link to={`${path}/help`} className="btn btn-warning btn-block">
              <Trans i18nKey="appHome.Sidebar.Button2">Bekijk de uitleg</Trans>
            </Link>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Home;
