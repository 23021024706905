import React from 'react';
import {Redirect, Route, Switch, useParams, useRouteMatch} from "react-router-dom";
import SubNav from "../../components/srl/SubNav";
import Section1 from "../../components/srl/_section1/";
import Section2 from "./_section2/";
import Section3 from "./_section3/";

const Srl = () => {
    let {path} = useRouteMatch();

    return (
        <>
            <SubNav />
            <div role="main" className="container">
                <Switch>
                    <Route path={path} exact render={() => <Redirect to={`${path}/1`} />} />
                    <Route path={`${path}/:step`} component={Step} />
                </Switch>
            </div>
        </>
    );
};

const Step = () => {
    let {step} = useParams();

    switch (parseInt(step)) {
        case 1:
        default:
            return <Section1 />
        case 2:
            return <Section2 />
        case 3:
            return <Section3 />
    }
};

export default Srl;
