import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  generatePath,
  Link,
  useRouteMatch,
  useHistory,
} from "react-router-dom";
import { connect } from "react-redux";
import { Trans, useTranslation } from "react-i18next";

import {
  mapTopicDeleteState,
  mapTopicDeleteTopicDispatch,
  mapTopicQuestionDitspatch,
  mapTopicQuestionState,
  mapTopicStatusDitspatch,
  mapTopicStatusState,
  mapTopicTitleDispatch,
  mapTopicTitleState,
} from "../../../containers/srl/_section2/mapping";
import Language from "../../../helpers/language";
import FormField from "../../global/FormField";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Form } from "react-bootstrap";

const TopicTitle = ({ topicTitle, setTopicTitle, topicId }) => {
  const { t } = useTranslation();
  const [title, setTitle] = useState(topicTitle);
  return (
    <>
      <Form>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>
            <Trans i18nKey="topic.title">Titel</Trans>:
          </Form.Label>
          <Form.Control
            type="text"
            style={{ maxWidth: "100%", fontSize: 12 }}
            placeholder={t("Titel van onderwerp")}
            defaultValue={title === "Nieuw onderwerp" ? "" : title}
            onChange={(e) => setTitle(e.target.value)}
            onBlur={() => setTopicTitle({ title, topicId })}
          />
        </Form.Group>
      </Form>
    </>
  );
};

const DeleteTopic = ({ topic, deleteTopic, activeTheme }) => {
  let { path } = useRouteMatch();
  let history = useHistory();

  const handleDelete = () => {
    const pathToTheme = generatePath(path, {
      step: 2,
      activeTheme: activeTheme.urlSlug,
    });

    deleteTopic({ topic, activeTheme });
    history.push(pathToTheme);
  };

  return (
    <span onClick={handleDelete}>
      <Trans i18nKey="topic.delete">Verwijder</Trans>
    </span>
  );
};

const Topic = ({
  activeTheme = { urlSlug: "" },
  topic = {},
  topicStatuses = [],
}) => {
  let { path } = useRouteMatch();
  const TopicTitleRedux = connect(
    mapTopicTitleState,
    mapTopicTitleDispatch
  )(TopicTitle);
  const TopicStatusRedux = connect(
    mapTopicStatusState,
    mapTopicStatusDitspatch
  )(TopicStatus);
  const TopicQuestionRedux = connect(
    mapTopicQuestionState,
    mapTopicQuestionDitspatch
  )(TopicQuestion);
  const DeleteTopicRedux = connect(
    mapTopicDeleteState,
    mapTopicDeleteTopicDispatch
  )(DeleteTopic);

  return (
    <div className="card">
      <div className="card-body bg-accent">
        <div className="row">
          <div className="col">
            {topic?.extraTopic ? (
              <TopicTitleRedux topicId={topic.id} />
            ) : (
              <h4 className="card-title mb-3">
                <span className="mr-1">
                  <Trans i18nKey={`topics.topic${topic.id}.title`}>
                    {topic.name}
                  </Trans>
                </span>
                <TopicInfoOverlay topic={topic} />
              </h4>
            )}
          </div>
        </div>
        {topicStatuses.length > 0 && (
          <div className="topic-radio row no-gutters">
            {topicStatuses.map((topicStatusId) => (
              <TopicStatusRedux
                topicId={topic.id}
                topicStatusId={topicStatusId}
                key={topicStatusId}
              />
            ))}
          </div>
        )}
        <div className="pt-3">
          {topic.topicQuestions.length > 0 && (
            <form onSubmit={(e) => e.preventDefault()}>
              {topic.topicQuestions.map((topicQuestionId) => (
                <TopicQuestionRedux
                  topicId={topic.id}
                  topicQuestionId={topicQuestionId}
                  key={topicQuestionId}
                />
              ))}
            </form>
          )}
        </div>
        <div className="d-flex align-items-center float-right">
          {topic?.extraTopic ? (
            <DeleteTopicRedux activeTheme={activeTheme} topic={topic} />
          ) : (
            <></>
          )}

          <Link
            className="btn btn-primary btn-sm ml-4"
            to={generatePath(path, {
              step: 2,
              activeTheme: activeTheme.urlSlug,
            })}
          >
            <Trans>{Language.getTranslation("backButton")}</Trans> &raquo;
          </Link>
        </div>
      </div>
    </div>
  );
};

const TopicInfoOverlay = ({ topic }) => {
  if (topic.description === "" || topic.description === null) {
    return null;
  }

  return (
    <OverlayTrigger
      trigger="click"
      placement="right"
      rootClose={true}
      overlay={
        <Popover id="popover-basic">
          <Popover.Title as="h3">
            <Trans i18nKey={`topics.topic${topic.id}.title`}>
              {topic.title}
            </Trans>
          </Popover.Title>
          <Popover.Content>
            <div style={{ whiteSpace: "pre-wrap" }}>
              <Trans i18nKey={`topics.topic${topic.id}.description`}>
                {topic.description}
              </Trans>
            </div>
          </Popover.Content>
        </Popover>
      }
    >
      <i className="fas fa-info-circle cursor-pointer" />
    </OverlayTrigger>
  );
};

const TopicStatus = ({
  activeTopicStatus,
  setTopicStatus,
  topicId,
  topicStatus,
  topicStatusId,
}) => {
  const { t } = useTranslation();

  // setTopicStatus expects an object with the topicId as key and new topicStatusId as value
  const handleClick = () => setTopicStatus({ [topicId]: topicStatusId });

  return (
    <FormField
      activeValue={activeTopicStatus}
      color={topicStatus.color}
      handleChange={handleClick}
      label={t(
        `topicStatus.status${topicStatus.id}.description`,
        topicStatus.name
      )}
      name="topicStatus"
      radioType="colorSwitch"
      type="radio"
      value={topicStatus.id}
    />
  );
};

const TopicQuestion = ({
  setTopicQuestion,
  topicQuestion,
  topicQuestionData,
  topic,
  setTopicQuestionTitle,
}) => {
  const [questionTitleState, setQuestionTitleState] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    setQuestionTitleState(topicQuestion.question);
  }, [topicQuestion]);
  // Safety check
  if (topicQuestion === undefined) {
    return null;
  }

  const handleChange = (newValue, event) =>
    setTopicQuestion({ [topicQuestion.id]: newValue });
  return (
    <>
      {topic?.extraTopic ? (
        // <Form>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label> {Language.getTranslation("question")}:</Form.Label>
          <Form.Control
            style={{ maxWidth: "100%", fontSize: 12 }}
            type="text"
            placeholder={t("Vraag van onderwerp")}
            defaultValue={questionTitleState}
            onChange={(e) => setQuestionTitleState(e.target.value)}
            onBlur={() =>
              setTopicQuestionTitle({ questionTitleState, topicQuestion })
            }
            //   defaultValue={topic}
          />
        </Form.Group>
      ) : (
        // </Form>
        <></>
      )}
      {console.log(topicQuestion)}
      <FormField
        handleChange={handleChange}
        label={
          topic?.extraTopic
            ? ""
            : t(
                `topicQuestions.topic${topicQuestion.id}.question`,
                topicQuestion.question
              )
        }
        name={topicQuestion.slug}
        type="wysiwyg"
        useSmallWysiwyg={topicQuestion.useSmallWysiwyg}
        value={topicQuestionData}
      />
    </>
  );
};

Topic.propTypes = {
  activeTheme: PropTypes.object.isRequired,
  topic: PropTypes.object.isRequired,
  topicStatuses: PropTypes.array.isRequired,
};
TopicStatus.propTypes = {
  activeTopicStatus: PropTypes.number.isRequired,
  topicId: PropTypes.number.isRequired,
  topicStatus: PropTypes.object.isRequired,
  topicStatusId: PropTypes.number.isRequired,
};
TopicQuestion.propTypes = {
  topicQuestion: PropTypes.object.isRequired,
  topicQuestionData: PropTypes.string.isRequired,
};

export default Topic;
