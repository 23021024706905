import React from "react";
import PropTypes from "prop-types";
import { Pie } from "react-chartjs-2";
import { defaults } from "react-chartjs-2";
import getInitialState from "../../containers/srl/reducers/initialState";

const ChartContainer = ({
  data = [],
  options = {},
  type = "pie",
  handleClick = undefined,
  chartRef,
}) => {
  if (data === undefined || data.length < 1) {
    return null;
  }

  switch (type) {
    case "pie":
      return (
        <PieChart
          data={data}
          options={options}
          handleClick={handleClick}
          chartRef={chartRef}
        />
      );
    default:
      return null;
  }
};

const getElementAtEvent = (event, handleClick) => {
  const chartElement = event[0];

  if (chartElement && handleClick !== undefined) {
    handleClick(chartElement._index, chartElement._datasetIndex);
  }
};

const PieChart = ({
  data,
  options = {},
  handleClick = undefined,
  chartRef,
}) => {
  defaults.global.elements.arc.borderWidth = 3;

  const defaultOptions = {
    legend: {
      display: false,
    },
    devicePixelRatio: 2,
    onHover: (event, chartElement) => {
      event.target.style.cursor = chartElement[0] ? "pointer" : "default";
    },
  };

  return (
    <Pie
      data={data}
      options={{ ...options, ...defaultOptions }}
      getElementAtEvent={(event) => getElementAtEvent(event, handleClick)}
      ref={chartRef}
    />
  );
};

const topicTooltip = (tooltipModel, _chart, datasets, t) => {
  if (datasets === undefined) {
    return;
  }

  // Tooltip Element
  let tooltipEl = document.getElementById("chartjs-tooltip");

  // Hide if no tooltip
  if (tooltipModel.opacity === 0 && tooltipEl !== null) {
    tooltipEl.style.opacity = 0;
    return;
  }

  // Get data
  const dataPoint = tooltipModel.dataPoints[0];
  const dataSet = datasets[dataPoint.datasetIndex];
  const dataItem = dataSet.isTopic
    ? dataSet.topics[dataPoint.index]
    : dataSet.themes[dataPoint.index];

  // Create element on first render
  if (!tooltipEl) {
    tooltipEl = document.createElement("div");
    tooltipEl.id = "chartjs-tooltip";
    tooltipEl.className = "bg-white border border-secondary";
    tooltipEl.innerHTML = '<div class="p-3"></div>';
    document.body.appendChild(tooltipEl);
  }

  if (dataItem === undefined || dataItem.hasOwnProperty("isSeparator")) {
    hideTooltip();
    return;
  }

  // Set caret Position
  tooltipEl.classList.remove("above", "below", "no-transform");
  if (tooltipModel.yAlign) {
    tooltipEl.classList.add(tooltipModel.yAlign);
  } else {
    tooltipEl.classList.add("no-transform");
  }

  // Set Text
  if (dataItem) {
    let innerHtml = "";

    // Title

    innerHtml += `<h4>${t(
      `topics.topic${dataItem.id}.title`,
      dataItem.name
    )}</h4>`;
    // Status
    if (dataSet.isTopic) {
      const statusName = dataItem.topicStatus?.name ?? "n.n.b.";
      innerHtml += `<p>${t("Score")}: ${t(statusName)}</p>`;
    }

    // Description
    const description = getItemDescription(dataItem);

    if (description) {
      if (dataSet.isTopic) {
        innerHtml += `<h5>${t("Toelichting")}:</h5>`;
      }
      innerHtml += `<div>${t(
        `topics.topic${dataItem.id}.description`,
        description
      )}</div>`;
    }

    const tooltipRoot = tooltipEl.querySelector("div");
    tooltipRoot.innerHTML = innerHtml;
  }

  // `this` will be the overall tooltip
  const position = _chart.canvas.getBoundingClientRect();

  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1;
  tooltipEl.style.position = "absolute";
  tooltipEl.style.left =
    position.left + window.pageXOffset + tooltipModel.caretX + "px";
  tooltipEl.style.top =
    position.top + window.pageYOffset + tooltipModel.caretY + "px";
  tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
  tooltipEl.style.fontSize = tooltipModel.bodyFontSize + "px";
  tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
  tooltipEl.style.padding =
    tooltipModel.yPadding + "px " + tooltipModel.xPadding + "px";
  tooltipEl.style.pointerEvents = "none";
  tooltipEl.style.maxWidth = "30rem";
};

const getItemDescription = (dataItem) => {
  if (dataItem.description) {
    return dataItem.description;
  }

  if (
    dataItem.hasOwnProperty("topicQuestions") &&
    dataItem.topicQuestions instanceof Array &&
    dataItem.topicQuestions.length > 0
  ) {
    const state = getInitialState();
    const topicQuestion =
      state.structure.topicQuestion[dataItem.topicQuestions[0]];

    if (topicQuestion) {
      return topicQuestion.question;
    }
  }

  return undefined;
};

const hideTooltip = (elementId = "chartjs-tooltip") => {
  // Tooltip Element
  let tooltipEl = document.getElementById(elementId);

  // Hide tooltip by settings opacity to 0
  if (tooltipEl !== null) {
    tooltipEl.style.opacity = 0;
  }
};

ChartContainer.propTypes = {
  data: PropTypes.object.isRequired,
  options: PropTypes.object,
  type: PropTypes.string,
  handleClick: PropTypes.func,
  chartRef: PropTypes.object,
};

PieChart.propTypes = {
  data: PropTypes.object.isRequired,
  options: PropTypes.object,
};

export { ChartContainer as default, topicTooltip, hideTooltip };
