import React from "react";
import PropTypes from "prop-types";
import { Spinner } from "react-bootstrap";

import Language from "../../helpers/language/";

const LoadingSpinner = ({ inline, message, small }) => {
  // Loading message can be left out, disabling the text
  // It can also be true, to use the default translation defined in the global translation file
  // It can also be a string, to use that string as translation key
  const loadingMessage =
    message === undefined
      ? false
      : message === true
      ? "loadingMessage"
      : message;

  return (
    <div
      className={
        inline ? "d-inline-block" : "d-flex my-5 justify-content-center"
      }
    >
      <Spinner animation="border" size={small ? "sm" : undefined} role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>
      {loadingMessage && (
        <p className={"ml-3 lead text-muted" + (inline && " d-inline-block")}>
          {Language.getTranslation(loadingMessage, "messages")}
        </p>
      )}
    </div>
  );
};

export default LoadingSpinner;

LoadingSpinner.propTypes = {
  inline: PropTypes.bool,
  loadingMessage: PropTypes.any,
  small: PropTypes.bool,
};
