import Language from "../../helpers/language";
import slugify from "slugify";

export const ExportHelper = {
  createFilename(projectName, extension = "pdf") {
    const name = projectName
      ? projectName
      : Language.getTranslation("emptyProjectName");

    return `${slugify(name)}.${extension}`;
  },
};
