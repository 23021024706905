import { defaultTopicStatus } from "../containers/srl/_section2/mapping";
import Colors from "../css/export-theme-for-react.scss";
import DarkColors from "../css/export-darken.scss";
import getInitialState from "../containers/srl/reducers/initialState";

export const StatusHelper = {
  getChartColorHex(topicStatuses, topicId) {
    const topicStatus = this.getTopicStatus(topicStatuses, topicId);
    return Colors[topicStatus.color];
  },
  getChartColor(topicStatuses, topicId) {
    const topicStatus = this.getTopicStatus(topicStatuses, topicId);
    return topicStatus.color;
  },
  getTopicStatus(topicStatuses, topicId) {
    const topicStatusId = topicStatuses[topicId] ?? defaultTopicStatus;

    // Data is stored here
    const state = getInitialState();
    const topicStatusData = state.structure.topicStatus;

    return topicStatusData[topicStatusId];
  },
  getChartHoverColorHex(topicStatuses, topicId) {
    const topicStatus = this.getTopicStatus(topicStatuses, topicId);

    return DarkColors[topicStatus.color + "-dark"];
  },
};
