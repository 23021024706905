import React from "react";
import { connect } from "react-redux";
import Chart, {
  hideTooltip,
  topicTooltip,
} from "../../../components/global/Chart";
import { mapOverviewState } from "./mapping";
import Colors from "../../../css/export-theme-for-react.scss";
import { StatusHelper } from "../../../helpers/statusHelper";
import { generatePath, useHistory, useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";

const OverviewChart = ({
  themes = {},
  themesOrdered = [],
  topics = {},
  topicQuestions = {},
  topicQuestionData = {},
  topicStatusData = {},
  chartRef = undefined,
}) => {
  const history = useHistory();
  let { path } = useRouteMatch();
  const { t } = useTranslation();

  if (themesOrdered.length === 0) {
    return null;
  }

  const data = PieData(
    themesOrdered,
    themes,
    topicStatusData,
    topics,
    topicQuestions,
    topicQuestionData
  );
  const options = PieOptions(data.datasets, t);
  const handleClick = (index, datasetIndex, dataset) => {
    hideTooltip();

    if (datasetIndex === 1) {
      // Inner circle
      const topic = dataset.topics[index];
      const theme = topic.theme;

      if (topic.hasOwnProperty("isSeparator")) {
        return;
      }

      history.push(
        generatePath(path, { step: 2, activeTheme: theme.urlSlug }) +
          "/" +
          topic.urlSlug
      );
    } else {
      // Outer circle
      const theme = dataset.themes[index];

      if (theme.hasOwnProperty("isSeparator")) {
        return;
      }

      history.push(generatePath(path, { step: 2, activeTheme: theme.urlSlug }));
    }
  };

  return (
    <Chart
      chartRef={chartRef}
      data={data}
      options={options}
      handleClick={(index, datasetIndex) =>
        handleClick(index, datasetIndex, data.datasets[datasetIndex])
      }
    />
  );
};

const PieData = (
  themesOrdered,
  themes,
  topicStatusData,
  topics,
  topicQuestions,
  topicQuestionData
) => {
  // Set segment width
  const separatorSegmentWidth = 2;
  const topicSegmentWidth = 10;

  // Assemble the datasets
  let themeValues = [];
  let themeData = [];
  let themeColors = [];
  let themeHoverColors = [];
  let topicValues = [];
  let topicData = [];
  let topicColors = [];
  let topicHoverColors = [];
  let topicBorderColors = [];
  let labels = [];
  let topicValuesSum = 0;

  themesOrdered.forEach((themeId) => {
    topicValuesSum = 0;
    themeData.push(themes[themeId]);
    themeColors.push(Colors["grijs-3"]);
    themeHoverColors.push("#b7b7b7");

    themes[themeId].topics.forEach((topicId) => {
      const statusColorHex = StatusHelper.getChartColorHex(
        topicStatusData,
        topicId
      );
      const statusHoverColorHex = StatusHelper.getChartHoverColorHex(
        topicStatusData,
        topicId
      );
      const topicStatus = StatusHelper.getTopicStatus(topicStatusData, topicId);

      // Check if topicStatus.id is equal to 2, and skip this topic if true
      if (topicStatus.id === 2) {
        return;
      }

      // Add the topic segment for the inner pie chart
      labels.push({ themeId: themeId, topicId: topicId });
      topicValues.push(topicSegmentWidth);
      topicValuesSum = topicValuesSum + topicSegmentWidth;
      topicData.push({
        ...topics[topicId],
        status: topicStatusData[topicId],
        statusColorHex,
        theme: themes[themeId],
        topicQuestions: topics[topicId].topicQuestions.map((questionId) => {
          return {
            ...topicQuestions[questionId],
            answer: topicQuestionData[questionId],
          };
        }),
        topicStatus,
      });
      topicColors.push(statusColorHex);
      topicHoverColors.push(statusHoverColorHex);
      topicBorderColors.push(Colors["white"]);
    });

    themeValues.push(topicValuesSum);

    // And add another smaller segment as a separator
    themeValues.push(separatorSegmentWidth);
    topicValues.push(separatorSegmentWidth);
    themeData.push({ isSeparator: true });
    themeColors.push(Colors["white"]);
    themeHoverColors.push(Colors["white"]);
    topicData.push({ isSeparator: true });
    topicColors.push(Colors["white"]);
    topicHoverColors.push(Colors["white"]);
    topicBorderColors.push(Colors["white"]);
  });

  return {
    datasets: [
      {
        backgroundColor: themeColors,
        borderAlign: "inner",
        borderColor: Colors["white"],
        borderWidth: 2,
        data: themeValues,
        hoverBackgroundColor: themeHoverColors,
        hoverBorderColor: Colors["white"],
        isTopic: false,
        labels: labels,
        themes: themeData,
        weight: 1,
      },
      {
        backgroundColor: topicColors,
        borderAlign: "inner",
        borderColor: topicBorderColors,
        borderWidth: 2,
        data: topicValues,
        hoverBackgroundColor: topicHoverColors,
        hoverBorderColor: topicBorderColors,
        isTopic: true,
        topics: topicData,
        weight: 8,
      },
    ],
  };
};

const PieOptions = (dataset, t) => {
  return {
    rotation: 3.7,
    cutoutPercentage: 5,
    tooltips: {
      enabled: false,
      custom: function (tooltipModel) {
        topicTooltip(tooltipModel, this._chart, dataset, t);
      },
    },
  };
};

export default connect(mapOverviewState)(OverviewChart);
