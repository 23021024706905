import React from "react";
import { NavLink, Route, useRouteMatch } from "react-router-dom";
import { Nav } from "react-bootstrap";
import { Trans } from "react-i18next";
import { useTranslation } from "react-i18next";

const SubNav = () => {
  let { url } = useRouteMatch();
  const { t } = useTranslation();

  return (
    <div className={"navbar-subnav"}>
      <div className={"container"}>
        <Nav className="nav-subnav">
          <NavItem label={`1. ${t("Start")}`} to={`${url}/1`} />
          <NavItem label={`2. ${t("SRL-vragen")}`} to={`${url}/2`} />
          <NavItem label={`3. ${t("Reflectie")}`} to={`${url}/3`} />
        </Nav>
      </div>
    </div>
  );
};

const NavItem = ({ exact, label, strict, to }) => {
  return (
    <Route path={to} exact={exact} strict={strict}>
      {({ match }) => (
        <li className={"nav-item " + (match ? "active" : "")}>
          <NavLink className="nav-link" exact={exact} strict={strict} to={to}>
            <Trans>{label}</Trans>
          </NavLink>
        </li>
      )}
    </Route>
  );
};

export default SubNav;
