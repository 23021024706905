import {setProjectInfo, toggleAllowContact} from "../../../containers/srl/actions";

/*
 State
 */
export const mapSection1State = state => {
    return {
        projectInfo: state.projectData.projectInfo,
        sectionQuestionsOptional: state.structure.section[4].sectionQuestions,
        sectionQuestionsRequired: state.structure.section[1].sectionQuestions,
    };
};

/*
 Dispatch
 */
export const mapSection1Dispatch = dispatch => {
    return {
        setProjectInfo: projectData => dispatch(setProjectInfo(projectData)),
        toggleAllowContact: projectData => dispatch(toggleAllowContact(projectData)),
    };
};