import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import Language from "../../helpers/language";
import { Form } from "react-bootstrap";
import PropTypes from "prop-types";
import Pdf from "../../containers/export/Pdf";
import Excel from "../../containers/export/Excel";
import { useTranslation, Trans } from "react-i18next";

const alertStyles = {
  global: {
    borderRadius: 0,
  },
  confirmBtnStyle: {
    boxShadow: 0,
    border: 0,
  },
};

const Confirm = (props) => {
  const { t } = useTranslation();

  return (
    <SweetAlert
      warning
      showCancel
      cancelBtnText={t("global.cancel", "Annuleren")}
      confirmBtnText={t(
        "emptyProjectDialog.confirmBtnText",
        "Maak nieuw project"
      )}
      confirmBtnBsStyle="warning"
      confirmBtnStyle={alertStyles.confirmBtnStyle}
      title={t("emptyProjectDialog.title", "Nieuw project aanmaken?")}
      onConfirm={props.onConfirm}
      onCancel={props.onCancel}
      focusCancelBtn
      openAnim={false}
      style={alertStyles.global}
    >
      {() => (
        <p>
          <Trans i18nKey="emptyProjectDialog.body">
            Aanpassingen die niet opgeslagen zijn worden verwijderd. Dit kan
            niet ongedaan gemaakt worden.
          </Trans>
        </p>
      )}
    </SweetAlert>
  );
};

const LoadProject = (props) => {
  const { t } = useTranslation();
  return (
    <SweetAlert
      showCancel
      title={t("openDialog.selectFile", "Selecteer .srl bestand")}
      onConfirm={props.onConfirm}
      onCancel={props.onCancel}
      focusCancelBtn
      confirmBtnText={t("global.open", "Open")}
      confirmBtnBsStyle="warning"
      confirmBtnStyle={alertStyles.confirmBtnStyle}
      confirmBtnCssClass={!props.fileIsValid ? "disabled" : ""}
      cancelBtnText={t("global.cancel", "Annuleren")}
      openAnim={false}
      style={alertStyles.global}
    >
      {() => (
        <form>
          <p>
            {t(
              "openDialog.body",
              "Hiermee wordt het bestaande project overschreven. Dit kan niet ongedaan gemaakt worden."
            )}
          </p>
          <Form.File
            id="custom-file"
            label={false}
            accept=".srl"
            onChange={(e) => props.handleFile(e.target.files[0])}
          />
        </form>
      )}
    </SweetAlert>
  );
};

const Success = (props) => {
  const {
    confirmBtnTxt = "confirmBtnTxt",
    titleTxt = "title",
    group = "successDialog",
  } = props;

  return (
    <SweetAlert
      success
      title={Language.getTranslation(titleTxt, group)}
      onConfirm={props.onConfirm}
      confirmBtnText={Language.getTranslation(confirmBtnTxt, group)}
      confirmBtnBsStyle="warning"
      confirmBtnStyle={alertStyles.confirmBtnStyle}
      openAnim={false}
      style={alertStyles.global}
    >
    </SweetAlert>
  );
};

const Error = (props) => {
  return (
    <SweetAlert
      warning
      confirmBtnBsStyle="warning"
      confirmBtnStyle={alertStyles.confirmBtnStyle}
      title="Fout bij versturen van formulier"
      onConfirm={props.onConfirm}
    />
  );
};

const ExportDialog = ({ onCancel, showLogo, onConfirm, format = "pdf" }) => {
  const { t } = useTranslation();

  return (
    <SweetAlert
      title={t("exportDialog.title", "Project opslaan")}
      onCancel={onCancel}
      onConfirm={onConfirm}
      showConfirm={false}
      openAnim={false}
      style={alertStyles.global}
    >
      {format === "pdf" && <Pdf showLogo={showLogo} />}
      {format === "excel" && <Excel />}
    </SweetAlert>
  );
};

export { Confirm as default, LoadProject, Success, Error, ExportDialog };

Success.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  confirmBtnTxt: PropTypes.string,
  titleTxt: PropTypes.string,
  bodyTxt: PropTypes.string,
  group: PropTypes.string,
};
