import React from "react";
import PropTypes from "prop-types";
import { Route, NavLink, useRouteMatch } from "react-router-dom";
import { Navbar as BsNavbar, Nav, Button, ButtonGroup } from "react-bootstrap";
import { Trans } from "react-i18next";

import config from "../../config/config";
import Language from "../../helpers/language";
import * as flags from "../../helpers/flags";

const Navbar = (props) => {
  let { path, url } = useRouteMatch();
  url = url === "/" ? "" : url;

  const activeLanguage = props.getLanguage;

  return (
    <BsNavbar bg="main" variant="light">
      <div className="container">
        <BsNavbar.Toggle aria-controls="jsNavbarNav">
          <Trans>Menu</Trans>
        </BsNavbar.Toggle>
        <BsNavbar.Collapse id="jsNavbarNav">
          <Nav className="mr-auto">
            <NavItem label="Home" exact={true} strict={true} to={path} />
            <NavItem label="Online SRL-tool" to={`${url}/srl`} />
            <NavItem label="Feedback" to={`${url}/feedback`} />
            <NavItem label="Uitleg" to={`${url}/help`} />
            <NavItem label="Contact" to={`${url}/contact`} />
          </Nav>
          <Nav>
            <li className="nav-item">
              <LanguageSwitcher
                activeLanguage={activeLanguage}
                changeLanguage={(lang) => {
                  Language.setLanguage(lang);
                  props.setLanguage(lang);
                }}
              />
            </li>
          </Nav>
        </BsNavbar.Collapse>
      </div>
    </BsNavbar>
  );
};

const NavItem = ({ exact, label, strict, to }) => {
  return (
    <Route path={to} exact={exact} strict={strict}>
      {({ match }) => (
        <li className={"nav-item " + (match ? "active" : "")}>
          <NavLink className="nav-link" exact={exact} strict={strict} to={to}>
            <Trans>{label}</Trans>
          </NavLink>
        </li>
      )}
    </Route>
  );
};

const LanguageSwitcher = (props) => {
  const languages = config.availableLanguages;
  const activeLanguage = props.activeLanguage;

  if (languages.length > 1) {
    return (
      <ButtonGroup>
        {languages.map((lang) => (
          <Button
            size="sm"
            variant={lang === activeLanguage ? "dark" : "outline-dark"}
            onClick={() => props.changeLanguage(lang)}
            key={lang}
          >
            <img
              src={flags[lang]}
              width={24}
              alt={`${lang.toUpperCase()} flag`}
            />
          </Button>
        ))}
      </ButtonGroup>
    );
  } else {
    return null;
  }
};

export default Navbar;

NavItem.propTypes = {
  exact: PropTypes.any,
  label: PropTypes.string.isRequired,
  strict: PropTypes.any,
  to: PropTypes.string.isRequired,
};
LanguageSwitcher.propTypes = {
  changeLanguage: PropTypes.func.isRequired,
};
