import helpers from "../../../helpers";
import {
  appDataSaved,
  deleteTopic,
  resetState,
  setNewAspect,
  setTopicQuestion,
  setTopicQuestionTitle,
  setTopicStatus,
  setTopicTitle,
} from "../actions";
import { StatusHelper } from "../../../helpers/statusHelper";
import { exportToJSON } from "../../../helpers/store";
import getInitialState from "../reducers/initialState";

/*
 State
 */

export const defaultTopicStatus = 3; // nog onbekend

// Srl project header
export const mapHeaderState = (state, ownProps) => {
  return {
    projectName: state.projectData.projectInfo.projectName,
    section: state.structure.section[ownProps.sectionId],
    exportToJSON: exportToJSON,
    changesSaved: state.app.changesSaved,
  };
};

// Theme index (nav)
export const mapThemeNavState = (state, ownProps) => {
  return {
    activeTheme: helpers.getItemFromObject(
      state.structure.theme,
      "urlSlug",
      ownProps.activeThemeUrlSlug
    ),
    section: state.structure.section[2],
  };
};
export const mapThemeButtonState = (state) => {
  return {
    themes: state.structure.theme,
  };
};

// Overview
export const mapOverviewState = (state) => {
  return {
    themes: state.structure.theme,
    themesOrdered: state.structure.section[2].themes,
    topics: state.structure.topic,
    topicQuestions: state.structure.topicQuestion,
    topicQuestionData: state.projectData.topicQuestion,
    topicStatusData: state.projectData.topicStatus,
  };
};
export const mapOverviewDispatch = (dispatch) => {
  return {
    appDataSaved: (data) => dispatch(appDataSaved(data)),
    resetState: (newState) => {
      if (newState === undefined) {
        // Project emptied
        newState = getInitialState();
      }

      dispatch(resetState(newState));
    },
  };
};

// Topic nav (left sidebar)
export const mapTopicNavHeaderState = (state, ownProps) => {
  return {
    activeTheme: state.structure.theme[ownProps.activeThemeId],
  };
};
export const mapTopicNavItemState = (state, ownProps) => {
  return {
    topic: state.structure.topic[ownProps.topicId],
  };
};
export const mapTopicNavState = (state, ownProps) => {
  return {
    activeTheme: state.structure.theme[ownProps.activeThemeId],
  };
};
export const mapTopicQuestionState = (state, ownProps) => {
  return {
    topic: state.structure.topic[ownProps.topicId],
    topicQuestion: state.structure.topicQuestion[ownProps.topicQuestionId],
    topicQuestionData:
      state.projectData.topicQuestion[ownProps.topicQuestionId] ?? "",
  };
};
export const mapTopicDeleteState = (state, ownProps) => {
  return {};
};
export const mapTopicState = (state, ownProps) => {
  return {
    activeTheme: state.structure.theme[ownProps.activeThemeId],
    topic: state.structure.topic[ownProps.activeTopicId],
    topicStatuses: state.structure.topicStatuses,
  };
};
export const mapTopicStatusState = (state, ownProps) => {
  const activeTopicStatus = StatusHelper.getTopicStatus(
    state.projectData.topicStatus,
    ownProps.topicId
  );

  return {
    activeTopicStatus: activeTopicStatus.id,
    topicStatus: state.structure.topicStatus[ownProps.topicStatusId],
  };
};

export const mapTopicTitleState = (state, ownProps) => {
  return {
    topicTitle: state.structure.topic[ownProps.topicId].name,
  };
};

export const mapTopicNewTopicState = (state, ownProps) => {
  return {
    activeTheme: state.structure.theme[ownProps.activeThemeId],
  };
};

export const mapTopicNavigationState = (state, ownProps) => {
  return {
    activeTheme: state.structure.theme[ownProps.activeThemeId],
    nextTheme: state.structure.theme[ownProps.activeThemeId + 1] ?? false,
    previousTheme: state.structure.theme[ownProps.activeThemeId - 1] ?? false,
  };
};

export const mapTopicSectionState = (state, ownProps) => {
  return {
    topics: state.structure.topic,
    themes: state.structure.theme ?? "",
  };
};

// Theme content
export const mapThemeChartState = (state, ownProps) => {
  const activeThemeUrlSlug = ownProps.activeTheme;
  const theme = helpers.getItemFromObject(
    state.structure.theme,
    "urlSlug",
    activeThemeUrlSlug
  );
  const topics = {};
  const topicStatuses = state.projectData.topicStatus;

  // Only populate the Topics for the selected Theme
  theme.topics.forEach((topicId) => {
    topics[topicId] = state.structure.topic[topicId];
    topics[topicId]["topicStatus"] = StatusHelper.getTopicStatus(
      topicStatuses,
      topicId
    );
  });

  return {
    theme: theme,
    topics,
    topicStatuses,
  };
};
export const mapThemeSwitchState = (state, ownProps) => {
  return {
    subComponent: ownProps.subComponent,
    themes: state.structure.theme,
    topics: state.structure.topic,
  };
};

export const mapChartLegendState = (state, ownProps) => {
  return {
    ...ownProps,
    topicStatusData: Object.values(state.structure.topicStatus),
  };
};

/*
 Dispatch
 */

export const mapNewTopicState = (state, ownProps) => {
  return {
    topics: state.structure.topic,
    themes: state.structure.theme ?? "",
  };
};

export const mapNewTopicDispatch = (dispatch) => {
  //   console.log(dispatch);
  return {
    setNewAspect: (data) => dispatch(setNewAspect(data)),
  };
};

// Topic nav (left sidebar)
export const mapTopicStatusDitspatch = (dispatch) => {
  return {
    setTopicStatus: (data) => dispatch(setTopicStatus(data)),
  };
};

export const mapTopicQuestionDitspatch = (dispatch) => {
  return {
    setTopicQuestion: (data) => dispatch(setTopicQuestion(data)),
    setTopicQuestionTitle: (data) => dispatch(setTopicQuestionTitle(data)),
  };
};
export const mapTopicDeleteTopicDispatch = (dispatch) => {
  return {
    deleteTopic: (data) => dispatch(deleteTopic(data)),
  };
};

export const mapTopicTitleDispatch = (dispatch) => {
  return {
    setTopicTitle: (data) => dispatch(setTopicTitle(data)),
  };
};

export const mapHeaderDispatch = (dispatch) => {
  return {
    appDataSaved: (data) => dispatch(appDataSaved(data)),
    resetState: (newState) => {
      if (newState === undefined) {
        // Project emptied
        newState = getInitialState();
      }

      dispatch(resetState(newState));
    },
  };
};
