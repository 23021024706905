import React from "react";
import PropTypes from "prop-types";
import {
  generatePath,
  Link,
  useRouteMatch,
  useHistory,
} from "react-router-dom";
import { connect } from "react-redux";
import { Trans } from "react-i18next";

import {
  mapNewTopicDispatch,
  mapNewTopicState,
  mapTopicNavItemState,
  mapTopicNewTopicState,
} from "../../../containers/srl/_section2/mapping";
import Icon from "../../global/Icon";

export const TopicNavHeader = ({ activeTheme }) => {
  return (
    <div className="media mb-4">
      <Icon
        className="svg-icon-fluid icon-blue w-25 mr-3"
        icon={activeTheme.icon}
      />
      <div className="media-body">
        <h3 className="mt-0">
          <Trans i18nKey={`theme.theme${activeTheme.id}.title`}>
            {activeTheme.name}
          </Trans>
        </h3>
        <p className="lead mb-0">
          <Trans i18nKey={`theme.theme${activeTheme.id}.description`}>
            {activeTheme.description}
          </Trans>
        </p>
      </div>
    </div>
  );
};

const NewTopicButton = ({ setNewAspect, topics, activeThemeId, themes }) => {
  let { path } = useRouteMatch();
  let history = useHistory();

  const onClick = async () => {
    const newTopicKey = Object.keys(topics).length + 1;
    setNewAspect({ newTopicKey, activeThemeId });
    const pathToNewAspect = generatePath(path, {
      step: 2,
      activeTheme: themes[activeThemeId].urlSlug,
      activeTopic: `newTopic${newTopicKey}`,
    });

    history.push(pathToNewAspect);
  };
  return (
    <button
      type="button"
      className="m-0 btn btn-primary btn-sm d-flex justify-content-space-between align-items-center"
      onClick={onClick}
    >
      <Trans i18nKey="topic.addSubject">Onderwerp toevoegen</Trans>

      <i className=" ml-1 fas fa-plus" />
    </button>
  );
};

export const TopicNewTopic = ({ activeThemeId }) => {
  const NewTopicButtonRedux = connect(
    mapNewTopicState,
    mapNewTopicDispatch
  )(NewTopicButton);

  return <NewTopicButtonRedux activeThemeId={activeThemeId} />;
};

export const TopicNav = ({ activeTheme }) => {
  const TopicNavItemRedux = connect(mapTopicNavItemState)(TopicNavItem);
  const TopicNewTopicRedux = connect(mapTopicNewTopicState)(TopicNewTopic);
  return (
    <>
      <div className=" mb-3 d-flex justify-content-between align-items-center">
        <h3 className="m-0">
          <Trans i18nKey="topic.subjects">Onderwerpen</Trans>
        </h3>
        {activeTheme?.hasExtraTopic ? (
          <></>
        ) : (
          <TopicNewTopicRedux activeThemeId={activeTheme.id} />
        )}
      </div>
      {activeTheme.topics.length > 0 && (
        <div className="bg-light">
          <ul className="list-group">
            {activeTheme.topics.map((topicId) => (
              <TopicNavItemRedux
                activeThemeUrlSlug={activeTheme.urlSlug}
                topicId={topicId}
                key={topicId}
              />
            ))}
          </ul>
        </div>
      )}
    </>
  );
};

const TopicNavItem = ({ activeThemeUrlSlug, topic }) => {
  let { path } = useRouteMatch();

  return (
    <li className="list-group-item font-weight-bold bg-accent mb-3">
      <Link
        to={generatePath(path, {
          step: 2,
          activeTheme: activeThemeUrlSlug,
          activeTopic: topic.urlSlug,
        })}
      >
        <Trans i18nKey={`topics.topic${topic.id}.title`}>{topic.name}</Trans>
      </Link>
    </li>
  );
};

export const TopicNavigation = ({
  activeTopicId,
  nextTheme,
  previousTheme,
}) => {
  let { path } = useRouteMatch();

  if (activeTopicId) {
    return null;
  }

  return (
    <div className="pt-2">
      {previousTheme ? (
        <Link
          to={generatePath(path, {
            step: 2,
            activeTheme: previousTheme.urlSlug,
          })}
        >
          <i className="fas fa-angle-left" />{" "}
          <Trans i18nKey={`theme.theme${previousTheme.id}.title`}>
            {previousTheme.name}{" "}
          </Trans>
        </Link>
      ) : (
        <Link to={generatePath(path, { step: 2 })}>
          <i className="fas fa-angle-left" /> <Trans>Overzicht</Trans>
        </Link>
      )}
      {nextTheme ? (
        <Link
          to={generatePath(path, { step: 2, activeTheme: nextTheme.urlSlug })}
          className="float-right"
        >
          <Trans i18nKey={`theme.theme${nextTheme.id}.title`}>
            {nextTheme.name}
          </Trans>{" "}
          <i className="fas fa-angle-right" />
        </Link>
      ) : (
        <Link to={generatePath(path, { step: 2 })} className="float-right">
          <Trans>Overzicht</Trans> <i className="fas fa-angle-right" />
        </Link>
      )}
    </div>
  );
};

TopicNavHeader.propTypes = {
  activeTheme: PropTypes.object.isRequired,
};
TopicNav.propTypes = {
  activeTheme: PropTypes.object.isRequired,
};
TopicNavItem.propTypes = {
  activeThemeUrlSlug: PropTypes.string.isRequired,
  topic: PropTypes.object.isRequired,
};
