import {StyleSheet} from "@react-pdf/renderer";

const spacer = 8.5;
const styles = StyleSheet.create({
    page: {
        fontFamily: 'ROSans',
        padding: '25mm',
        fontSize: 8.5
    },
    firstPage: {
        fontFamily: 'ROSans',
        padding: '0 25mm 25mm 25mm',
        fontSize: 8.5
    },
    section: {
        marginBottom: spacer,
    },
    row: {
        flexDirection: 'row',
    },
    col: {
        flexGrow: 1,
    },
    tr: {
        flexDirection: 'row',
        marginTop: -1
    },
    td: {
        flexGrow: 1,
        border: '1pt solid #ddd',
        padding: '5'
    },
    label: {
        flexGrow: 0,
        width: 150,
        marginRight: -1
    },
    text: {
        marginBottom: spacer,
    },
    mb3: {
        marginBottom: spacer
    },
    mb4: {
        marginBottom: 2 * spacer
    },
    small: {
        fontSize: 8
    },
    h1: {
        fontSize: 12,
        fontWeight: 700
    },
    h2: {
        fontSize: 10,
        marginBottom: spacer
    },
    h3: {
        fontWeight: 700,
    },
    h4: {
        fontSize: 11,
        fontWeight: 700,
    },
    h5: {
        fontSize: 9,
    },
    topicStatus: {
        flexGrow: 0,
        width: 20,
        height: 20,
        marginRight: spacer
    },
    circle: {
        marginRight: spacer / 2,
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        borderBottomRightRadius: 10,
        borderBottomLeftRadius: 10,
        flexGrow: 0,
        width: 10,
        height: 10,
    },
    pageNumbers: {
        position: 'absolute',
        bottom: 20,
        left: 0,
        right: 0,
        textAlign: 'center'
    },
    chartOverview: {
        width: 270,
        marginLeft: 95,
        marginBottom: 50,
        marginTop: 40
    },
    icon: {
        position: 'absolute',
        top: 0,
        left: 0,
        fontWeight: 700,
        fontSize: 11
    },
    chartOverviewIcon: {
        width: 40,
        position: 'absolute',
    },
    icon1: {
        top: 130,
        left: 180,
    },
    iconImage1: {
        top: 85,
        left: 178,
    },
    icon2: {
        top: 120,
        left: 340,
    },
    iconImage2: {
        top: 75,
        left: 385,
    },
    icon3: {
        top: 260,
        left: 460,
    },
    iconImage3: {
        top: 215,
        left: 455,
    },
    icon4: {
        top: 460,
        left: 325,
    },
    iconImage4: {
        top: 415,
        left: 340,
    },
    icon5: {
        top: 350,
        left: 110,
    },
    iconImage5: {
        top: 305,
        left: 105,
    }
});

export {styles as default, spacer};
